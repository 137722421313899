import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const AgreementToSignTemplate = () => {
  return (
    <>
    <EmailTemplate title='Email Agreement To Sign Confirmation'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='signature_needed'
    />
    </>
  )
}

export default AgreementToSignTemplate