import Popper from '@mui/material/Popper';
import ColorPicker from 'react-pick-color';
import { useState, useRef, useEffect } from 'react';
import "../../../../assets/styles/containers/_colorPicker.scss";
import { ClickAwayListener, Grow, MenuList } from "@mui/material";
import React from 'react'


/**
 * A ColorPicker component for the Comment Library summary
 */
const RSColorPicker = ({ setColor, color, setIsChanged, colorPalette }) => {
    // Reference to the element the ColorPicker is anchored to
    const anchorRef = useRef(null);

    // Whether the ColorPicker is open or not
    const [open, setOpen] = useState(false);

    // Currently selected color
    const [selectedColor, setSelectedColor] = useState(color);

    // Toggle the ColorPicker open or closed
    const handleToggle = () => { setOpen((prevOpen) => !prevOpen); }

    // Close the ColorPicker when a click event occurs outside of it
    const handleClose = (event) => {
        // Check if the click event happened inside the anchor element
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        // Close the ColorPicker
        setOpen(false);
        // handleSummaryColorChange(event, row);
    };

    useEffect(() => {
        setSelectedColor(color)
    }, [color])

    // Handle color change from ColorPicker
    const handleColorChange = (color) => {
        setSelectedColor(color.hex); // Update selected color state
        console.log("here")
        setColor(color.hex);
        setIsChanged(true);
    };

    const handleColorTextChange = (color) => {
        const hexRegex = /^#([0-9A-Fa-f]{0,6})$/; // Allow up to 6 hex characters after #
        const colorValue = color.target.value;

        // Only allow the input to contain up to 6 valid hex characters
        if (hexRegex.test(colorValue)) {
            setColor(colorValue);  // Valid hex code, update the state
            setIsChanged(true);
        } else {
            // If invalid hex code or too many characters, don't change the state
            // Optionally, you can set the input value to the previous valid value or leave it as is.
            color.target.value = color.target.value.slice(0, 7); // Restrict to 7 characters (including #)
        }
    };

    return (
        <div style={{ paddingTop: "5px", cursor: "auto !important" }}>
            {/* The anchor element for the ColorPicker */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid #E5E8E8",
                    backgroundColor: "white",
                    borderRadius: "6px",
                    width: "100%",
                    height: "32px",
                    padding: "5px",
                    gap: "5px",
                }}

                ref={anchorRef}
            >
                {/* The colored div element */}
                <div
                    style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "2px",
                        backgroundColor: selectedColor,
                        cursor: "pointer"
                    }}
                    onClick={handleToggle}
                ></div>
                {/* Editable color */}
                <input
                    type="text"
                    value={selectedColor}
                    onChange={(e) => handleColorTextChange(e)}
                    style={{
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        fontSize: "13px",
                        width: "80px",
                        textAlign: "center",
                        margin: "-3px 0px 0px -13px"
                    }}
                />
            </div>
            <Popper
                transition
                open={open}
                disablePortal
                role={undefined}
                placement='left'
                anchorEl={anchorRef.current}
                modifiers={[
                    {
                        name: 'arrow',
                        options: {
                            element: '[data-popper-arrow]',
                        },
                    },
                ]}
                style={{ zIndex: 999, width: "370px" }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'center top' }}
                    >
                        {/* The ColorPicker component */}
                        <div className="color-picker-popper" style={{ marginRight: "5px", backgroundColor: "white" }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <ColorPicker
                                        className="edit-summary-color-picker"
                                        hideAlpha
                                        color={selectedColor}
                                        onChange={handleColorChange} // Update to handleColorChange
                                        theme={{
                                            background: "#fff",
                                            boxShadow: "none",
                                            borderColor: "#fff",
                                            width: "100%"
                                        }}
                                    />
                                    <div className="color-pallets" style={{ padding: "0px 15px 0px 15px", marginLeft: "20px" }}>
                                        {colorPalette.map((value, index) => {
                                            return (
                                                <div className="pallet-wrapper" style={{ border: selectedColor === value ? "1px solid #979797" : "" }}>
                                                    <span
                                                        className="pallet"
                                                        style={{ background: value }}
                                                        id={`color_palette_${index + 1}`}
                                                        onClick={() => {
                                                            setSelectedColor(value);
                                                            setColor(value);
                                                            setIsChanged(true);
                                                        }}
                                                    ></span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {/* <span className="popper-arrow" data-popper-arrow></span> */}
                                </MenuList>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default RSColorPicker;