import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { apiCallDelete } from "../../../utils/action";
const CLDeleteSummaryConfirm = ({ handleCloseConfirmationModal, deleteConfirmationModal, deletedSummaryId, setDeletedSummaryId, GetCLSummaryList, setDeleteConfirmationModal }) => {
    const handleDeleteSummary = () => {

        const payload = {
            "summary_id": deletedSummaryId,
        };
        apiCallDelete(
            (response) => {
                const { success, message } = response.data;
                if (success) {
                    setDeletedSummaryId("")
                    setDeleteConfirmationModal(false);
                    GetCLSummaryList();
                } else {
                    console.error("Update failed:", message);
                }
            },
            payload,
            "DELETE_COMMENT_LIB_SUMMARY"
        );
    }
    return (<Dialog
        open={deleteConfirmationModal}
        onClose={handleCloseConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-alert-modal"
    >

        <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion?"}
            <CloseIcon className="close-icon" onClick={handleCloseConfirmationModal} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Summary? It will also be removed from the comments it is assigned to.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className='options' onClick={handleCloseConfirmationModal}><span>No</span></Button>
            <Button className='add-new-btn' onClick={handleDeleteSummary}><span>Yes</span></Button>
        </DialogActions>
    </Dialog>)
}
export default CLDeleteSummaryConfirm;