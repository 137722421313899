import React from 'react'
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TemplateAdd = (props) => {
  const {name, from} = props;
  return (
    <Grid sx={{backgroundColor:'#ffffff', height: from === 'subcat' ? '60px' : '54px', 
    // marginTop: from === 'subcat' ? '0px' : '15px'
    }} onClick = {() => props.HandleCreateSite()} className="templates__add">
        <AddCircleOutlineIcon />
        <Typography className="templates__add__text">
        {name}
        </Typography>
    </Grid>
  )
}

export default TemplateAdd