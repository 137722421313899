import React from 'react'
import Box from "@mui/material/Box";
// common components imports
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";
import AgreementToSignTemplate from '../../components/NotificationSettings/AgreementToSignTemplate'

const AgreementToSign = () => {
  return (
    <>
    {window.scrollTo(0, 0)}
    <Box sx={{ flexGrow: 1 }} className="page-background">
      <Header />
      <Breadcrumb />
      <AgreementToSignTemplate/>
    </Box>
  </>
  )
}

export default AgreementToSign