import React from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { NewSiteList } from "../../../config/template-constants";

const NewSiteDetail = (props) => {
const {siteValues} = props
  return (
    <>
      <Grid
        container
        style={{ paddingTop: "34px" }}
        spacing={2}
        className="inspection-filter-modal-margins"
      >
        <Grid item lg={11.4} xs={11.4} sm={11.4}>
          <FormControl fullWidth>
            <label className="inspection-filter-modal-label" sx={{ mb: "8px" }}>
              NAME
            </label>
            <TextField
              style={{ paddingTop: "0px !important" }}
              type="text"
              size="small"
              name="name"
              placeholder="Detail name"
              onChange={(e) => siteValues.name(e.target.value)}
              value={siteValues.siteName}
            />
          </FormControl>
        </Grid>
        <Grid sx={{ mt: "12px" }} item lg={11.4} xs={11.4} sm={11.4}>
          <FormControl fullWidth>
            <label className="inspection-filter-modal-label" sx={{ mb: "8px" }}>
              ANSWER FORMAT
            </label>
            <Autocomplete
              options={NewSiteList}
              filterSelectedOptions
              size="small"
                value={siteValues.siteId}
              getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => siteValues.value(newValue)}
              renderInput={(params) => (
              
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} placeholder="Select answer format"  />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default NewSiteDetail;
