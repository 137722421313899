import Box from "@mui/material/Box";
// common components imports
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import PaymentSettings from "../../../components/PaymentsDetails/PaymentSettings";

const Payments = () => {
  return (
    <>    
    {window.scrollTo(0, 0)}    
      <Box sx={{ flexGrow: 1 }} className="page-background">
       <Header/>
       <Breadcrumb/>
         <PaymentSettings/>
      </Box>
    </>
  )
}

export default Payments