import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";

const MonthlySummary = (props) => {
  const { summaryData, summaryCount, isMobile } = props;

  const Count = (total) => {
    if (total === 0) {
      return summaryCount.inspection_scheduled !== undefined
        ? summaryCount.inspection_scheduled
        : 0;
    }
    if (total === 1) {
      return summaryCount.inspection_complete !== undefined
        ? summaryCount.inspection_complete
        : 0;
    }
    if (total === 2) {
      return `$${
        summaryCount.total_paid !== undefined ? summaryCount.total_paid?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
      }`;
    }
    if (total === 3) {
      return `$${
        summaryCount.fees_owed !== undefined ? summaryCount.fees_owed?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
      }`;
    }
  };
  const [activeChild, setActiveChild] = React.useState(0);

  // Basically items = [1, 2, 3, 4]

  // The Keypress Event Handler
  const changeChild = React.useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) => (a - 1 < 0 ? summaryData.length - 1 : a - 1));
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) => (a + 1 > summaryData.length - 1 ? 0 : a + 1));
      }
    },
    [summaryData]
  );

  // Set and cleanup the event listener
  React.useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });
  return (
    <Grid container>
      <Typography
        sx={{ ml: isMobile && "23px", }}
        align="left"
        className="dashboard__summary"
      >
        This Month’s Summary
      </Typography>
      {isMobile ? (
        //  <Box
        //     display="grid"
        //     gridTemplateColumns="repeat(12, 1fr)"
        //     style={{ gap: "33px", width: "100%" }}
        //   >
        <Carousel
          index={activeChild} // <-- This controls the activeChild
          autoPlay={true} // <-- You probaly want to disable this for our purposes
          navButtonsAlwaysVisible
          sx={{ width: "100%", height: "220px" }}
        >
          {summaryData.map((data, i) => {
            return (
              <Box
                style={{ backgroundColor: data.color }}
                gridColumn="span 3"
                id={`summary_id${i}`}
                name={`summary_name${i}`}
                className="dashboard__summary__box"
              >
                <div className="dashboard__summary__box__icon">{data.icon}</div>
                <h3 className="dashboard__summary__box__total">
                  {Count(data.id)}
                </h3>
                <h3 className="dashboard__summary__box__title">{data.title}</h3>
              </Box>
            );
          })}
        </Carousel>
      ) : (
        // </Box>

        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          style={{ gap: "33px", width: "100%", wordWrap:"break-word" }}
        >
          {summaryData.map((data, i) => {
            return (
              <Box
                style={{ backgroundColor: data.color }}
                gridColumn="span 3"
                id={`summary_id${i}`}
                name={`summary_name${i}`}
                className="dashboard__summary__box"
              >
                <div className="dashboard__summary__box__icon">{data.icon}</div>
                <h3 className="dashboard__summary__box__total">
                  {Count(data.id)}
                </h3>
                <h3 className="dashboard__summary__box__title">{data.title}</h3>
              </Box>
            );
          })}
        </Box>
      )}
    </Grid>
  );
};

export default MonthlySummary;
