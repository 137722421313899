import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Breadcrumb from '../../../common/header/Breadcrumb'
import Header from '../../../common/header/Header'
import PTCompanyDetailsView from '../../../components/PTadmin/Company/PTCompanyDetailsView';

const PTCompanyDetails = () => {
    const location = useLocation();
    const companyID = location?.state? location?.state?.id:'';
    const editCompany = location.state ? location.state.edit : false;
  return (
    <>
     <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header/>
        <Breadcrumb/>
        <PTCompanyDetailsView companyID={companyID} editCompany={editCompany}/>
    </Box>
    </>
  )
}

export default PTCompanyDetails