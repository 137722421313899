import React from "react";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from '@mui/icons-material/Done';

const EditButton = (props) => {
  const {isEdit, EditList} = props;
  return (
    <Button onClick = {() => EditList()} className="EditButton">
      {!isEdit ? <ModeEditIcon sx={{ fontSize:'16px'}} /> : <DoneIcon sx={{ fontSize:'16px'}} />}
      <span className="EditButton__text">{ !isEdit ? "Edit list" : "Done editing"}</span>
    </Button>
  );
};

export default EditButton;
