import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import SvgIcon from "@mui/material/SvgIcon";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { arrowDown } from "../../../assets/svgIcons/Svg";
import { timestamp } from "../../utils/services";
import FroalaEditorComponent from "../froalaHtmlEditor/FroalaEditorComponent";

const froalaConfig = {
  heightMin: 127,
  heightMax: 300,
  imagePaste: false,
  spellCheck: true,
  toolbarButtons: {
    moreText: { buttons: ["bold", "italic", "underline", "fontFamily", "fontSize", "textColor", "backgroundColor", "alignLeft", "alignCenter", "alignRight"], align: "left", buttonsVisible: 3 },
    moreRich: { buttons: ["insertLink", "emoticons", "specialCharacters", "fontAwesome", "formatOLSimple", "formatUL", "outdent", "indent"], align: "left", buttonsVisible: 0 },
    moreMisc: { buttons: ["undo", "redo"], align: "right", buttonsVisible: 2 },
  },
  pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
};

const NarrativeCommentSection = (props) => {
  const { commentData, categoryId, lineId, AllCategoryList, setAllCategoryList, updateTemplate, commentId } = props;
  const commentEditor = useRef();
  const summaryEditor = useRef();
  const storeData = useSelector((state) => state.commentLibrary);
  const selectedTagIdsList = commentData?.tags?.map((item) => item.id);
  const selectedSummaryTagIdsList = commentData?.summaries?.map((item) => item.id);
  const filteredTagItems = storeData?.clTagList?.filter((item) => selectedTagIdsList.includes(item.id));
  const filteredSummaryTagItems = storeData?.clSummaryTagList?.filter((item) => selectedSummaryTagIdsList.includes(item.id));
  const [state, setState] = useState({
    open: false,
    tagOpen: false,
    commentSummaryTag: filteredSummaryTagItems || [],
    commentTag: filteredTagItems || [],
    commentText: { innerHTML: commentData?.comment_text || "" },
    summaryText: { innerHTML: commentData?.summary_comment_text || "" },
  });
  const { open, tagOpen, commentSummaryTag, commentTag, commentText, summaryText } = state;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      commentSummaryTag: filteredSummaryTagItems || [],
      commentTag: filteredTagItems || [],
      commentText: { innerHTML: commentData?.comment_text || "" },
      summaryText: { innerHTML: commentData?.summary_comment_text || "" },
    }));
  }, [commentData]);

  const handleUpdateTemplateData = (data) => {
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    let lineIndex = catList[categoryIndex].lines.findIndex((line) => line.line_id === lineId);
    let commentIndex = catList[categoryIndex].lines[lineIndex].comments.findIndex((comment) => comment.comment_id === commentId);
    catList[categoryIndex].lines[lineIndex].comments[commentIndex] = { ...catList[categoryIndex].lines[lineIndex].comments[commentIndex], ...data, updated_at: timestamp() };
    setAllCategoryList((prev) => {
      const newCategoryList = {...AllCategoryList};
      newCategoryList.template.categories = catList;
      newCategoryList.template.categories.updated_at = timestamp();

      return newCategoryList;
    });

    updateTemplate("noToast");
  };

  const handleDropdownList = (name, value) => {
    if (name === "commentText") {
      setState((prevState) => ({ ...prevState, commentDropdownList: value }));
    } else {
      setState((prevState) => ({ ...prevState, summaryDropdownList: value }));
    }
  };

  const handleEditorChange = (name, content) => {
    setState((prevState) => ({ ...prevState, [name]: { innerHTML: content } }));
  };
  const handleChangeTag = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    const modifiedArray = value?.map((obj) => ({
      id: obj.id,
      name: obj?.title || obj?.name,
      color: obj.color,
    }));
    handleUpdateTemplateData(name === "commentTag" ? { tags: modifiedArray } : { summaries: modifiedArray });
  };
  const handleChangetext = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
    handleUpdateTemplateData(name === "commentText" ? { comment_text: value } : { summary_comment_text: value });
  };
  const handleChangeDropdown = (event, dropdownUuid, type, commentUUId, commentDisabled) => {
    const selectedValue = event.target.value;
    const catList = AllCategoryList?.template?.categories;
    const categoryIndex = catList.findIndex((cat) => cat.category_id === storeData?.showClModal?.selectedCategory?.id);
    let lineIndex = catList[categoryIndex]?.lines.findIndex((line) => line.line_id === storeData?.showClModal?.selectedLine?.line_id);
    let commentIndex = catList[categoryIndex]?.lines[lineIndex].comments.findIndex((comment) => comment?.comment_id === commentId);
    let commentDropdownIndex = catList[categoryIndex]?.lines[lineIndex].comments[commentIndex].comment_dropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
    let summaryDropdownIndex = catList[categoryIndex]?.lines[lineIndex].comments[commentIndex].summary_comment_dropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
    catList[categoryIndex].lines[lineIndex].comments[commentIndex] = { ...catList[categoryIndex].lines[lineIndex].comments[commentIndex], updated_at: new Date().toISOString() };
    if (type === "comment")
      catList[categoryIndex].lines[lineIndex].comments[commentIndex].comment_dropdowns[commentDropdownIndex] = {
        ...catList[categoryIndex].lines[lineIndex].comments[commentIndex].comment_dropdowns[commentDropdownIndex],
        selected_value: selectedValue,
      };
    else
      catList[categoryIndex].lines[lineIndex].comments[commentIndex].summary_comment_dropdowns[summaryDropdownIndex] = {
        ...catList[categoryIndex].lines[lineIndex].comments[commentIndex].summary_comment_dropdowns[summaryDropdownIndex],
        selected_value: selectedValue,
      };
      setAllCategoryList((prev) => {
        const newCategoryList = {...AllCategoryList};
        newCategoryList.template.categories = catList;
        newCategoryList.template.categories.updated_at = timestamp();

        return newCategoryList;
      });
    updateTemplate("noToast");
  };

  // Rebind event listeners
  const rebindDropdownListeners = () => {
    let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
    commentDropElements.forEach((element) => {
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const commentDisabled = element.getAttribute("disabled");
      if (element) {
        element.onchange = (event) => handleChangeDropdown(event, element.id, "comment", commentUUId, commentDisabled);
      }
    });

    let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
    summaryDropElements.forEach((element) => {
      const dropType = element.getAttribute("dropType");
      const commentUUId = element.getAttribute("comment_uuid");
      const commentDisabled = element.getAttribute("disabled");
      if (element) {
        element.onchange = (event) => handleChangeDropdown(event, element.id, "summary", commentUUId, commentDisabled);
      }
    });
  };

  rebindDropdownListeners();

  useEffect(() => {
    const summaryEditorData = summaryEditor.current;
    const commentEditorData = commentEditor.current;
    summaryEditorData?.events?.on("contentChanged", () => {
      rebindDropdownListeners();
    });
    commentEditorData?.events?.on("contentChanged", () => {
      rebindDropdownListeners();
    });
    rebindDropdownListeners();
    return () => {
      summaryEditorData?.events?.off("contentChanged");
      commentEditorData?.events?.off("contentChanged");
    };
  }, [AllCategoryList]);

  return (
    <>
      <Typography className="comment-title-text">Comment</Typography>
      <FroalaEditorComponent
        editor={commentEditor}
        id={"commentText"}
        onChange={handleEditorChange}
        editorData={commentText}
        onBlur={handleChangetext}
        handleDropdownList={handleDropdownList}
        froalaConfig={froalaConfig}
        commentData={commentData}
      />
      <Typography className="comment-title-text">TAGS</Typography>
      <Autocomplete
        className="autoHover autocompleteCustom"
        id="tags-outlined"
        multiple
        size="small"
        options={storeData?.clTagList || []}
        openOnFocus
        open={tagOpen}
        defaultValue={commentTag || []}
        defaultChecked={commentTag || []}
        value={commentTag}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.title}
        onChange={(e, value) => handleChangeTag("commentTag", value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseOutlinedIcon />} label={option?.title || option?.name} {...getTagProps({ index })} />)
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id}>
            <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={commentTag?.some((tag) => tag.id === option.id)} />
            <Box
              sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
              style={{
                marginRight: 8,
              }}
            />
            {option?.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            value={commentTag}
            defaultValue={commentTag}
            placeholder={commentTag?.length === 0 ? "Add tags" : ""}
            onClick={() => setState((prevState) => ({ ...prevState, tagOpen: !tagOpen }))}
            onBlur={() => setState((prevState) => ({ ...prevState, tagOpen: false }))}
            InputProps={{
              ...params.InputProps,
              style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400, paddingLeft: commentTag?.length !== 0 ? "0px" : "20px" },
              endAdornment: (
                <>
                  <SearchIcon className={`customSearch ${commentTag?.length !== 0 ? "hidden" : ""}`} style={{ color: "#9b9b9b", marginRight: "8px" }} />
                  <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={() => setState((prevState) => ({ ...prevState, tagOpen: !tagOpen }))}>
                    {arrowDown}
                  </SvgIcon>
                </>
              ),
            }}
          />
        )}
      />
      <Typography className="comment-title-text">Summary Comment</Typography>
      <FroalaEditorComponent
        editor={summaryEditor}
        onChange={handleEditorChange}
        id={"summaryText"}
        editorData={summaryText}
        onBlur={handleChangetext}
        froalaConfig={froalaConfig}
        commentData={commentData}
      />
      <Typography className="comment-title-text">Summary Section</Typography>
      <Autocomplete
        className="autoHover autocompleteSummaryCustom"
        id="tags-outlined"
        multiple
        size="small"
        options={storeData?.clSummaryTagList || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.title}
        openOnFocus
        open={open}
        defaultChecked={commentSummaryTag || []}
        defaultValue={commentSummaryTag || []}
        value={commentSummaryTag}
        onChange={(e, value) => handleChangeTag("commentSummaryTag", value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              deleteIcon={<CloseOutlinedIcon />}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: `${option?.color} !important`,
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  />
                  <div style={{ fontSize: "14px" }}>{option?.name || option?.title}</div>
                </Box>
              }
              {...getTagProps({ index })}
              sx={{
                padding: "2px 2px 2px 10px !important",
                fontSize: "10px !important",
              }}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option?.id}>
            <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={commentSummaryTag?.some((tag) => tag.id === option.id)} />
            <Box
              sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
              style={{
                marginRight: 8,
              }}
            />
            {option?.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            defaultValue={commentSummaryTag}
            value={commentSummaryTag}
            placeholder={commentSummaryTag?.length === 0 ? "No summary selected" : ""}
            onClick={() => setState((prevState) => ({ ...prevState, open: !open }))}
            onBlur={() => setState((prevState) => ({ ...prevState, open: false }))}
            InputProps={{
              ...params.InputProps,
              style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
              endAdornment: (
                <>
                  <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={() => setState((prevState) => ({ ...prevState, open: !open }))}>
                    {arrowDown}
                  </SvgIcon>
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default NarrativeCommentSection;
