import React from "react";
import Box from "@mui/material/Box";
import { Grid} from "@mui/material";

const AgreementActionCard = ({icon,title,content,email}) => {
  return (
    <Box className="agreement-action-wrapper page-background">
      <Grid className="agreement-accept-action">
        <div>
          {icon}
          <p className="signature-header">{title}</p>
        </div>
        <p className="signature-content">
         {content} <span> {email && `"${email}."`} </span>
        </p>
      </Grid>
    </Box>
  );
};

export default AgreementActionCard;
