import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from 'react-router-dom'

const PageNotFound = (props) => {
  const navigate = useNavigate();
  let roleId = sessionStorage.getItem("role_id");

  const handleClose = () => {
    if(Number(roleId) === 1 || Number(roleId) === 2 ||Number(roleId) === 3 ||Number(roleId) === 4  )navigate('/company');
    else navigate('/')
  };

  return (
    <div>
      <Dialog
        open={true}
        fullWidth='true'
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{fontSize:'28px',textAlign:'center'}} id="alert-dialog-title">
         {props.type}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign:"center",fontSize:'18px',margin:'30px 0px'}} id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display:'flex',justifyContent:'center'}}>
          <Button className="default-btn" style={{color:'#fff',marginBottom:'15px'}} onClick={handleClose} autoFocus>
            {props.action}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PageNotFound;
