import { Divider } from "@mui/material";
import Box from "@mui/material/Box";

const Heading = (props) => {
  const { title, icon, iconDesc, CancelledStatus } = props;
  return (
    <>
      <Box className="details" sx={{ marginTop: "0px !important", border: "0px !important" }}>
        <div className="details__title">
          <div style={{ fontSize: "18px" }}>{title}</div>
        </div>

        <div style={{display : CancelledStatus && 'none'}} className="details__link">
          {icon}
          <div className="details__text">{iconDesc}</div>
        </div>
      </Box>
      <Divider />
    </>
  );
};

export default Heading;
