import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const PaymentRequestTemplate = () => {
  return (
    <>
    <EmailTemplate title='Send Payment Request'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='payment_request'
    />
    </>
  )
}

export default PaymentRequestTemplate