import React from 'react'
import Breadcrumb from '../../common/header/Breadcrumb';
import Header from '../../common/header/Header';
import TemplateDetails from '../../components/TemplateEditors/TemplateDetails';
import { useLocation } from "react-router-dom";

const TemplateEditor = () => {
  const location = useLocation();
  const templateId = location.state?.id;
  const templateName = location.state?.name;
  return (
    <div>
        <Header/>
        <Breadcrumb/>
        <TemplateDetails templateId={templateId} templateName={templateName} />
    </div>
  )
}

export default TemplateEditor