import React from "react";
import { Box } from "@mui/system";
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";
import NotificationCenter from "../../components/NotificationSettings/NotificationCenter";
// styles imports
import "../../../assets/styles/containers/_notificationCenter.scss";

const Notifications = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <NotificationCenter />
      </Box>
    </>
  );
};

export default Notifications;
