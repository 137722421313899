import Box from "@mui/material/Box";
// common components imports
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";
import SignatureNeededTemplate from "../../components/NotificationSettings/SignatureNeededTemplate";

const SignatureNeeded = () => {
  return (
    <>
    {window.scrollTo(0, 0)}
    <Box sx={{ flexGrow: 1 }} className="page-background">
      <Header />
      <Breadcrumb />
      <SignatureNeededTemplate/>
    </Box>
  </>
  )
}

export default SignatureNeeded