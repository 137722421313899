import React from 'react'
import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const AppointmentConfirmationTemplate = () => {  
  return (
    <>
    <EmailTemplate title='Email Appointment Confirmation'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='appointment_confirmation'
    />
    </>
  )
}

export default AppointmentConfirmationTemplate