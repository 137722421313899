import React from 'react'
import { useNavigate,useLocation,Link } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AgreementActionCard from './AgreementActionCard';

const AcceptAgreement = () => {
    const location = useLocation();
    const emailParam = location.state?.emailParam;
  return (
    <>
    <AgreementActionCard
    icon={<CheckCircleOutlineIcon style={{color:'#239d80'}}/>}
    title={"Agreement signed successfully"}
    content={"A signed copy of the agreement is sent to your email address"}
    email={emailParam}
    />    
    </>
  )
}

export default AcceptAgreement