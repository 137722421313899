import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import Heading from "../../common/cardHeading/Heading";
import EditIcon from "@mui/icons-material/Edit";
const Templates = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1, mt:3 }}>
        <Grid container>
          <Paper
            elevation={0}
            sx={{
              minHeight: "169px",
              border: "1px solid #E7E7E9",
              width: "100%",
            }}
          >
            <Heading
              title={"Templates"}
              icon={<EditIcon className="details__icon"> </EditIcon>}
              iconDesc={"Edit"}
            />
            {/* mapping function for the contact screen (CLIENT)******************** */}
            <Grid container spacing={0} sx={{ mt: "14px", pl: "25px" }}>
              {/* mapping function for the contact screen (BUYER)******************** */}
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};

export default Templates;
