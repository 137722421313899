import React from "react";
import { Typography, Grid } from "@mui/material";
import AddImageButton from "./AddImageButton";
import { addImgDescText } from "../../config/template-constants2";
import DeleteIcon from "@mui/icons-material/Delete";
import { timestamp } from "../../utils/services";

const ImageSection = (props) => {
  const {
    btnDescriptionFlag,
    AllCategoryList,
    item,
    isPlaceHolder,
    categoryId,
    lineId,
    lineInputControlId,
    updateTemplate,
    images,
  } = props;

  const templateGuid = AllCategoryList.template.guid;
  let baseUrl = AllCategoryList.bucket_url;

  const deleteImage = (pathId) => {
    const catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex(
      (cat) => cat.category_id === categoryId
    );
    const lineIndex = catList[categoriesDataIndex].lines.findIndex(
      (line) => line.line_id === lineId
    );
    const locationIndex = catList[categoriesDataIndex].lines[
      lineIndex
    ].line_input_controls.findIndex(
      (info) => info.line_input_control_id === lineInputControlId
    );
    let selected =
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].data;
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[
        locationIndex
      ].updated_at = timestamp();
    selected.map((sel) => {
      if (sel.id === pathId) {
        sel.is_deleted = 1;
        sel.updated_at = timestamp();
        updateTemplate("noToast");
      }
    });
  };
  return (
    <Grid sx={{ marginBottom: "50px" }}>
      {/* <Typography className='line-header'>
            Media
        </Typography> */}
      <Grid container className="template-image-wrapper">
        {images.map((img, index) => {
          const url = baseUrl.concat(img?.onlineUrl);
          return (
            img.is_deleted !== 1 ?
            <div>
              <img
                alt="img"
                src={url}
                key={index}
                style={{ objectFit: "contain" }}
              />
              <DeleteIcon
                onClick={() => deleteImage(img.id)}
                className="delete-wrappers"
              />
            </div> : ''
          );
        })}
      </Grid>
      <AddImageButton
        isPlaceHolder={isPlaceHolder}
        item={item}
        templateGuid={templateGuid}
        AllCategoryList={AllCategoryList}
        categoryId={categoryId}
        images={images}
        lineId={lineId}
        lineInputControlId={lineInputControlId}
        updateTemplate={updateTemplate}
      />
      {btnDescriptionFlag && (
        <Typography className="btn-desc">{addImgDescText}</Typography>
      )}
      <p
        style={{ marginTop: "6.5px", textTransform: "none" }}
        className="line-sub-head"
      >
        Add a photo or diagram to your template to be part of each inspection by
        default.
      </p>
    </Grid>
  );
};

export default ImageSection;
