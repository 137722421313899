import React,{useState} from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditIcon from "@mui/icons-material/Edit";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AddLocationMultiple from '../../components/TemplateEditors/TemplateModals/AddLocationMultiple';

const LocationInput = (props) => {
   const {lineName,locationData,AllCategoryList, cmtLocHolder, isPlaceHolder, item, categoryId,lineId,lineInputControlId,updateTemplate,action,
    commentId,locationValue=[]} = props;
   const [open,setOpen] = useState(false);
      const handleClick = () => {
        setOpen(true)
      };
  return (
      <>    
        {/* <AddLocation open={open} setOpen={setOpen} lineName={lineName}/> */}
        <AddLocationMultiple open={open} 
        setOpen={setOpen} 
        cmtLocHolder={cmtLocHolder}
        lineName={lineName} 
        isPlaceHolder={isPlaceHolder}
        item={item}
        locationData={locationData}
        AllCategoryList={AllCategoryList}
        categoryId={categoryId}
        lineId={lineId}
        lineInputControlId={lineInputControlId}
        updateTemplate={updateTemplate}
        commentId={commentId}
        action={action}
        locationValue={locationValue}/>
        <FormControl sx={{ m: 1,width:'100%',margin:'0'}} variant="outlined">
            <OutlinedInput
                disabled
                id="outlined-adornment-weight"
                className='location-input'
                size="small"
                type="text"
                fullWidth
                sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                value={locationValue && locationValue.length!== 0 ? locationValue?.map(el=>el.name).join(' '):''}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton    
                        disableRipple               
                        aria-label="toggle password visibility"
                        onClick={handleClick}
                        edge="end"
                    >
                        {<EditIcon/>} <span className='edit-text'>Edit</span>
                    </IconButton>
                    </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                placeholder="Default location goes here"
            />
        </FormControl>
      </>
     
  )
}

export default LocationInput