//file imports
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { apiCall } from '../../utils/action';
import Preloader from '../../helpers/Preloader';

const RequestPayment = () => {
    const [showLoader, setShowLoader] = useState();
    const [source, setSource] = useState('https://palmtechdev.securepayments.cardpointe.com/pay?');
    const params = window.location.hash;
    const value = params?.split('?');
    const queryString = value[1]?.split('&');
    //api call
    const GetData = () => {
        setShowLoader(true);
        const formdata = { inspection_id: queryString === undefined ? '' : queryString[0], user_id: queryString === undefined ? '' : queryString[1] };
        apiCall(
            (response) => {
                if (response.data.success) {
                    setSource(response.data.url);
                    setShowLoader(false);
                } else {
                    console.log('else', response.data.message);
                    toast.error(response.data.message);
                    setSource('https://www.palmtech.com/');
                    setShowLoader(false);
                }
            },
            formdata,
            'GET_REQUEST_PAYMENT_DATA',
        );
    };
    useEffect(() => {
        GetData();
    }, []);

    return (
        <>
            <Preloader showPreloader={showLoader} />
            <iframe src={source} className="request-payment"></iframe>
        </>
    );
};

export default RequestPayment;
