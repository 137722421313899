import { API_CONSTANTS, deleteApiCall, getApiCall, postApiCall, putApiCall } from "../../config/api-constants";
import toast from "react-hot-toast";

export const SHOW_CL_MODAL = "SHOW_CL_MODAL";
export const GET_CL_LIST = "GET_CL_LIST";
export const GET_CL_TAG_LIST = "GET_CL_TAG_LIST";
export const GET_CL_SUMMARY_TAG_LIST = "GET_CL_SUMMARY_TAG_LIST";
export const ADD_NEW_NARRATIVE_COMMENT = "ADD_NEW_NARRATIVE_COMMENT";
export const EDIT_NARRATIVE_COMMENT = "EDIT_NARRATIVE_COMMENT";
export const UPDATE_DROPDOWN_VALUES = "UPDATE_DROPDOWN_VALUES";
export const DELETE_NARRATIVE_COMMENT = "DELETE_NARRATIVE_COMMENT";
export const GET_FULL_CL_LIST = "GET_FULL_CL_LIST";
export const SET_EDITOR_LISTNER = "SET_EDITOR_LISTNER";

export const setShowCommentLibraryModal = (value) => {
  return {
    type: SHOW_CL_MODAL,
    payload: value,
  };
};

export const getCommentLibraryList = (page = null, pageCount = null) => {
  return async (dispatch) => {
    try {
      let countString = "";
      if (page) countString = `?page=${page}`;
      else if (pageCount) countString = `?page_count=${pageCount}`;
      let response;
      if (page || pageCount) {
        response = await postApiCall(`${API_CONSTANTS.GET_COMMENT_LIBRARY_LIST}${countString}`);
      }
      if (page) return dispatch({ type: GET_CL_LIST, payload: response?.data?.data });
      else if (pageCount) return dispatch({ type: GET_FULL_CL_LIST, payload: response?.data?.data });
    } catch (error) {
      throw error;
    }
  };
};

export const getCommentLibraryTagList = () => {
  return async (dispatch) => {
    try {
      const response = await getApiCall(API_CONSTANTS.GET_COMMENT_LIB_TAGS_LIST);
      return dispatch({ type: GET_CL_TAG_LIST, payload: response?.data?.data || [] });
    } catch (error) {
      throw error;
    }
  };
};

export const getCommentLibrarySummaryTagList = () => {
  return async (dispatch) => {
    try {
      const response = await getApiCall(API_CONSTANTS.GET_COMMENT_LIB_SUMMARY_TAG_LIST);
      return dispatch({ type: GET_CL_SUMMARY_TAG_LIST, payload: response?.data?.data || [] });
    } catch (error) {
      throw error;
    }
  };
};

export const addNewNarrativeComment = (newCommentData) => {
  return async (dispatch) => {
    try {
      const newAddData = {
        ...newCommentData,
        tag_ids: newCommentData?.tag_ids?.filter((item) => item !== "")?.map((tag) => parseInt(tag.id)) || [],
        summary_ids: newCommentData?.summary_ids?.filter((item) => item !== "")?.map((summaryTag) => parseInt(summaryTag.id)) || [],
      };
      const response = await postApiCall(API_CONSTANTS.ADD_COMMENT_NARRATIVE, newAddData);
      if (response?.data?.success)
        return dispatch({ type: ADD_NEW_NARRATIVE_COMMENT, payload: { ...newCommentData, tags: newCommentData?.tag_ids, summaries: newCommentData?.summary_ids, id: response?.data?.data?.id } || [] });
      else toast.error(response?.data?.message);
    } catch (error) {
      throw error;
    }
  };
};

export const editNarrativeComment = (editCommentdata) => {
  return async (dispatch) => {
    try {
      const editedData = {
        ...editCommentdata,
        tag_ids: editCommentdata?.tag_ids?.filter((item) => item !== "")?.map((tag) => parseInt(tag.id)) || [],
        summary_ids: editCommentdata?.summary_ids?.filter((item) => item !== "")?.map((summaryTag) => parseInt(summaryTag.id)) || [],
      };
      const response = await putApiCall(API_CONSTANTS.EDIT_COMMENT_BY_ID, editedData);
      if (response?.data?.success) return dispatch({ type: EDIT_NARRATIVE_COMMENT, payload: { ...editCommentdata, tags: editCommentdata?.tag_ids, summaries: editCommentdata?.summary_ids } || [] });
    } catch (error) {
      throw error;
    }
  };
};

export const updateDropDownValues = (data) => {
  return async (dispatch) => {
    const response = await putApiCall(API_CONSTANTS.EDIT_COMMENT_DROPDOWN_BY_ID, data);
    if (response?.data?.success) return dispatch({ type: UPDATE_DROPDOWN_VALUES, payload: data || [] });
    try {
    } catch (error) {
      throw error;
    }
  };
};

export const getCommentById = (id) => {
  return async (dispatch) => {
    try {
      const response = await getApiCall(`${API_CONSTANTS.GET_COMMENT_BY_ID}?comment_id=${id}`);
      if (response?.data?.success) return response.data.data;
      else return null;
    } catch (error) {
      throw error;
    }
  };
};

export const deleteCommentById = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteApiCall(`${API_CONSTANTS.DELETE_COMMENT_BY_ID}?comment_id=${id}`);
      if (response?.data?.success) {
        dispatch({ type: DELETE_NARRATIVE_COMMENT, payload: id || [] });
        const response = await dispatch(getCommentLibraryList(1));
        return response?.payload?.data;
      } else return null;
    } catch (error) {
      throw error;
    }
  };
};

export const setEditorListner = (value) => {
  return { type: SET_EDITOR_LISTNER, payload: value };
};
