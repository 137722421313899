import React, { useEffect, useState } from "react";
import "./FroalaEditorStyles.scss";
import Froalaeditor from "froala-editor";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import BulbIcon from "../../../assets/images/bulbIcon.png";
// import AICommentHelper from "../../Components/LineDetails/CommentDetails/AICommentHelper";

const FroalaWithoutDropdown = (props) => {
  const {
    categoryName,
    categoryNameFromLocation,
    showAIModal,
    setShowAIModal,
    setCommentText,
    commentTitle,
    showAI,
    editor,
    id,
    onChange,
    editorData,
    onBlur,
    commentData,
    copy,
    froalaConfig,
    toolbarButtonsConfig,
  } = props;
  const [state, setState] = useState({
    open: false,
    newOption: "",
    openHelp: false,
    dropdownList: [],
    textfield: false,
    hoverOption: null,
    editedOption: null,
    dialogOptionList: [],
    selectedDropdown: null,
  });
  const { openHelp } = state;

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    setTimeout(() => {
      if ((!editorContent || editorContent === undefined || copy) && editorInstance && editorData?.innerHTML) {
        editorInstance?.html?.set(editorData?.innerHTML);
      }
    }, 100);
  }, []);

  const handleModelChange = (model) => {
    onChange(id, model);
  };

  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
    setShowAIModal(!showAIModal);
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });

  if (showAI) {
    toolbarButtonsConfig.aiHelper = { buttons: ["aiHelper"], align: "left" };
  }

  const handleSetComment = (values) => {
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    if (cursorPosition) {
      editorInstance.html.insert(`<div><div>${values}</div></div>`);
    }
  };

  return (
    <div id={id}>
      <FroalaEditor
        id={id}
        ref={editor}
        value={editorData}
        onModelChange={handleModelChange}
        config={{
          key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
          spellCheck: true,
          imagePaste: false,
          placeholderText: " ",
          charCounterCount: false,
          quickInsertEnabled: false,
          toolbarButtons: toolbarButtonsConfig,
          events: {},
          ...froalaConfig,
        }}
      />
      {/* <AICommentHelper
        categoryName={categoryName}
        categoryNameFromLocation={categoryNameFromLocation}
        showAIModal={showAIModal}
        setShowAIModal={setShowAIModal}
        id={id}
        editor={editor}
        setCommentText={setCommentText}
        commentTitle={commentTitle}
        open={openHelp}
        onClose={openHelpDialog}
        handleSetComment={handleSetComment}
      /> */}
    </div>
  );
};

export default FroalaWithoutDropdown;
