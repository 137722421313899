import React from 'react'
import Box from "@mui/material/Box";

// common components imports
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import AgreementsListView from '../../../components/AgreementsDetails/AgreementsListView';

const AgreementsList = () => {
  return (
    <>    
    {window.scrollTo(0, 0)}    
      <Box sx={{ flexGrow: 1 }} className="page-background">
       <Header/>
       <Breadcrumb/>
         <AgreementsListView />
      </Box>
    </>
  )
}

export default AgreementsList