import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography } from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from "@mui/icons-material/Delete";
import CryptoJS from 'crypto-js'
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import {BootstrapTooltip} from "../../../utils/services";
import S3 from "react-aws-s3";
import { apiCall } from '../../../utils/action';
window.Buffer = window.Buffer || require("buffer").Buffer;

const AddDocument = (props) => {
    
  const {open,setOpen, attachments, attId,attachStatus,  handleClose,validation, LoadList, templateDocument, templateId, companyId, uuId, roleId, setTemplateDocument} = props;
    const fileInput = React.useRef();
    const Fetchfile = () => fileInput.current.click();
    const [NameError, setNameError] = useState(false)
    const [FileError, setFileError] = useState(false)
    const [url, setUrl] = useState()
    const [fileType, setFileType] = useState()
    const [fileName, setFileName] = useState('')
    const [fileData, setFileData] = useState("")
    const [file, setFile] = useState()

    const selectedAttachments = attachments?.length > 0 && attachments?.filter((elem) => {
      return elem.id === attId
    })

    const handleUploadImage  = () => {
      const payload = { 
        company_id: companyId,
        user_id:uuId,
        role_id:roleId,
        template_id:templateId,
        s3_url : url,
        file_type:fileType ? fileType.split(".")[1] : "",
        order:1,
        file_name:fileName
      }

      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            // setDefaultRatingColors(data)
            setUrl(data.location)
            setFileType(data.key)
            setFileData(data)
            LoadList()
            // setShowLoader(false);

          } else {
            // toast.error(message);
            // setShowLoader(false);
            console.error("else ", message);
          }
        },
        payload,
        "UPLOAD_ATTACHMENTS"
      );
    }

    const handleUpdateImage = () => {

    }

    const CryptoJSAesDecrypt = (passphrase,encrypted_json_string) => {

      // var obj_json = JSON.parse(encrypted_json_string);

       var encrypted = 'fzk/dS/hfdOa3zxZk9U7frHDIv+iEx+QvWqfX39VHAc=';
        var salt = CryptoJS.enc.Hex.parse('35d7c370dddf3a98a36fb3988c5594fced33fa1282a80f2863bdb4f7db1f5dbd017e16847d958fc33997af906959f70c16fd044575b989e8e0063297886b944db1ab5b49876159ba481d9ac554dfbfc2bc1df7bae0cd479eee4a877aa562588ead41ad928161b74a8b5f33966107b2dce26e2f85cc4bbe02e57bbb22a7edf5a7656bebd1a14bc48504ff8dcc222af36b4dc31a1e37ce20c29fcc3484274624a9dcaa6d280da8095b7be1073d2d811f74dd999173f889e494480f2a1bcc0363f1d0f2b918598f839b5c14e2670942058cf88dc6215a616fa4b5a70a429399cb32ba6e0da30f61aedab4a8d6d3cc8c61bce084af0f05bd8a541a8ae322996d03ab');
        var iv = CryptoJS.enc.Hex.parse('0bef740a5541e9a8efacaa3f9ba73053');   
       var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});
       return decrypted.toString(CryptoJS.enc.Utf8);
    }
    

    const handleClick = (event) => {
      let file = event.target.files[0];
      let newFileName = file?.name;
      const config = {
        bucketName: "reportwriters",
        // dirName: "pt-develop-media" /* optional */,
        region: "ap-south-1",
        accessKeyId: "AKIA42S6LDN36HSZZA3G",
       secretAccessKey: CryptoJSAesDecrypt('6affc323-8ed1-4f4c-978f-943e9c606b6b'),
        
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data.status === 204) {
          setFileData(data);
        } else {
          console.error("fail");
        }
      });
    };


    const AddAttachment = () => {
      if(fileName === "") {
        setNameError(true)
      } else {
        setNameError(false)

      }
      if(fileData === "") {
        setFileError(true)
      } else {
        setFileError(false)
      }

      if(!(fileName === "" || fileData === "")){
        handleUploadImage()
      }

    }

    useEffect(() => {
      if(attachStatus === "edit"){
        setFileData(selectedAttachments[0])
        console.log(selectedAttachments)
      }
    },[attachStatus])

  return (
    <>
      <Dialog
          fullWidth={true}
          className="dialog-container-template"
          open={open}
          onClose={handleClose}
        >
          <div className="close-wrapper-template">
            <CloseIcon onClick={handleClose} />
          </div>
          <DialogTitle className='template-dialog-title'>{"Attachment"}</DialogTitle>
          <DialogContent className="dialog-content-template">
            <Grid container className="input-controll">
              <Grid item xs={12} >
                <FormControl variant="standard" className="forms-control-template">
                  <label className="input-form-label" htmlFor="Name">
                  Document Name
                  </label>
                  <TextField
                    id="Name"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    placeholder="Name"
                    onChange={(e) => setFileName(e.target.value)}
                    error={NameError}
                    helperText={NameError ? "name cannot be blank" : ""}
                    value={fileName}
                    type="text"
                  />
                </FormControl>
              </Grid>
              {fileData !== "" &&
             <Grid item xs={12} >
                <span className='template-lists'>
                        <span>{fileData?.key}</span>
                        <span>
                        <BootstrapTooltip 
                                      title={<h1 className="font-13">Remove document</h1>}>
                                      <DeleteIcon className="delete-icon" onClick={()=>setFileData('')} />  
                                </BootstrapTooltip>
                        </span>
                </span>
              </Grid>
             }
              <Grid item xs={12} >
             
      
              <span className="add_section" onClick={() => Fetchfile()}>
              <input
                ref={fileInput}
                type="file"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                onChange={handleClick}
                style={{ display: "none" }}
            />
                <ControlPointIcon className="add-icon" /> Browse file
                </span>
               {FileError && <Typography sx={{fontSize : '12px', color:'red', mt:"5px", textAlign: 'center'}} >Please select a file</Typography>}
              </Grid>

             
            </Grid>
          </DialogContent>
          <DialogActions className="dialog-action">
            <Button className="back-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="default-btn" onClick={() => AddAttachment()}>
              Add
            </Button>
          </DialogActions>
        </Dialog></>
  )
}

export default AddDocument