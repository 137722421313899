export const tempImages = [
    "https://images.unsplash.com/photo-1659398652648-b3b8b7c1beab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    "https://images.unsplash.com/photo-1616137303871-05ce745f9cdb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80",
    "https://media.istockphoto.com/photos/interior-living-room-3d-illustration-picture-id689586164?s=612x612"
];

export const addImgDescText = "Add informational photo. This can be used for schematics or maintenence guides to use in the field."

export const locationData =
    {
        direction:[
            {
                id:1,
                name:'North',
                status:false
            }
        ],
        areas:[
            {
                id:1,
                name:"kitchen",
                status:false,
            }
        ],
        area2:[
            {
                id:1,
                name:'attic',
                status:false
            }
        ]
    }

    export const direction=[
        {   
            name:"North",
            status:false,
            indexPosition:null,
        },
        {
            name:'Kitchen',
            status:false,
            indexPosition:null,
        },
        {
            name:'1st floor',
            status:false,
            indexPosition:null,
        },
        {
            name:"South",
            status:false,
            indexPosition:null,
        },
        {
            name:"Bedroom",
            status:false,
            indexPosition:null,
        },
        {
            name:'2nd floor',
            status:false,
            indexPosition:null,
        },    
        {
            name:"Northeast",
            status:false,
            indexPosition:null,
        },
        {
            name:'bathroom',
            status:false,
            indexPosition:null,
        },
        {
            name:'3rd floor',
            status:false,
            indexPosition:null,
        },
        {
            
            name:"Southeast",
            status:false,
            indexPosition:null,
        },
        {
            
            name:"livingroom",
            status:false,
            indexPosition:null,
        },
        {
            
            name:'4th floor',
            status:false,
            indexPosition:null,
        },        
        {
            
            name:"Northwest",
            status:false,
            indexPosition:null,
        },
        {
            
            name:'hall',
            status:false,
            indexPosition:null,
        },
        {
            
            name:'5th floor',
            status:false,
            indexPosition:null,
        },
        {
            
            name:"Southwest",
            status:false,
            indexPosition:null,
        },
        {
            
            name:"verandah",
            status:false,
            indexPosition:null,
        },
        {
            
            name:'6th floor',
            status:false,
            indexPosition:null,
        },        
        {
            
            name:"Southeast",
            status:false,
            indexPosition:null,
        },
        {
            
            name:'porch',
            status:false,
            indexPosition:null,
        },
        {
            
            name:'basement',
            status:false,
            indexPosition:null,
        },  
    ]

    export const directionMultiple = [
        {
            value: "North",
            order: "1",
            col : "1"
        },
        {
            value: "Kitchen",
            order: "1",
            col : "2"
        },
        {
            value: "1st Floor",
            order: "1",
            col : "3"
        },
        {
            value: "East",
            order: "2",
            col : "1"
        },
        {
            value: "Bathroom",
            order: "2",
            col : "2"
        },
        {
            value: "2nd Floor",
            order: "2",
            col : "3"
        },
        {
            value: "West",
            order: "3",
            col : "1"
        },
        {
            value: "washroom",
            order: "3",
            col : "2"
        },
        {
            value: "3rd Floor",
            order: "3",
            col : "3"
        },
        {
            value: "South",
            order: "4",
            col : "1"
        },
        {
            value: "porch",
            order: "4",
            col : "2"
        },
        {
            value: "Basement",
            order: "4",
            col : "3"
        },
        {
            value: "South East",
            order: "5",
            col : "1"
        },
        {
            value: "Dining",
            order: "5",
            col : "2"
        },
        {
            value: "4th Floor",
            order: "5",
            col : "3"
        },
        {
            value: "North East",
            order: "6",
            col : "1"
        },
        {
            value: "Kitchen",
            order: "6",
            col : "2"
        },
        {
            value: "1st Floor",
            order: "6",
            col : "3"
        },
        {
            value: "North East",
            order: "7",
            col : "1"
        },
        {
            value: "Kitchen",
            order: "7",
            col : "2"
        },
        {
            value: "1st Floor",
            order: "7",
            col : "3"
        },
    ]
 export const editorData= `<p> <span style="color: rgb(0, 0, 0);font-size: medium">Florida Inspections does a pretty decent job with our inspections. We have been in business for over ten years, and have never fallen off the roof.</span><span style="font-size: medium;color: rgb(153, 0, 133)">&nbsp;
 </span><em><span style="color: rgb(255, 0, 221)">Give us a try today.</span></em> </p>`

 export const TempRating=[
    {
        id:1,
        name:'Defective',
        summary:true,
        color:'#E30303'
    },
    {
        id:2,
        name:'Marginal',
        summary:false,
        color:'#1f2f9c'
    }
 ]