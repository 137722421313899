import React from "react";
import Box from "@mui/material/Box";
// common components imports
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";
import AppointmentConfirmationTemplate from '../../components/NotificationSettings/AppointmentConfirmationTemplate'

const AppointmentConfirmation = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <AppointmentConfirmationTemplate/>
      </Box>
    </>
  );
};

export default AppointmentConfirmation;
