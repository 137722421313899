import React from 'react'
import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const RescheduleConfirmationTemplate = () => {
  return (
    <>
    <EmailTemplate title='Email Reschedule Confirmation'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='inspection_rescheduled'
    />
    </>
  )
}

export default RescheduleConfirmationTemplate