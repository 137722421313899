import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const PaymentReminderTemplate = () => {
  return (
    <>
    <EmailTemplate title='Send Payment Reminder'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='payment_reminder'
    />
    </>
  )
}

export default PaymentReminderTemplate