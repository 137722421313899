import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BootstrapTooltip } from "../../utils/services";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertImage from "../../../assets/images/alert.png";
import toast from "react-hot-toast";
import { CreateNewTemplate, GetExistingTemplates, RemoveTemplate, RequestTemplateDetails, UpdateTemplateReq, RequestTemplateDetails2 } from "./action";
import { LoadingButton } from "@mui/lab";

const EditTemplate = (props) => {
  const { open, onClose, load, isEdit, templateId, refresh, typeDetails, basedDetails, setBasedDetails, setTypeDetails, deleteControl, setTemplateDetails } = props;

  const [values, setValues] = React.useState({
    name: "",
    report_title: "",
    description: "",
  });
  const location = useLocation();
  const [loadingToggle, setLoadingToggle] = useState(false);

  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [basedOn, setBasedOn] = React.useState(null);
  const [InspType, setInspType] = React.useState(null);
  const [isDefault, setIsDefault] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [name, setName] = React.useState("");

  const formatLetter = (value) => {
    return value.slice(0, 100);
  };

  const handleChange = (event) => {
    const val = event.target.name === "name" ? formatLetter(event.target.value) : event.target.value;

    setValues({
      ...values,
      [event.target.name]: val.charAt(0).match(/[%<>\\$'#@!~^&*()_+{}"]/) ? val?.substring(1).replace(new RegExp("/", "g"), "") : val.replace(new RegExp("/", "g"), ""),
    });
  };

  const handleClear = () => {
    setValues({
      name: "",
      report_title: "",
      description: "",
    });
    onClose();
    setBasedOn(null);
    setDataIsCorrect(false);
    setErrors({});
    setInspType(null);
    setIsDefault(false);
  };

  const OpenDeleteModal = (name) => {
    setName(name);
    setDeleteModal(true);
    handleClear();
  };

  const CloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const GetTemplateDetails = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    RequestTemplateDetails2((response) => {
      if (response.data.success) {
        setTypeDetails(response?.data?.data?.inspection_types);
        setBasedDetails(response?.data?.data?.based_on);
      } else {
        console.error("else", response.data.message);
      }
    }, formdata);
  };

  const validation = (values) => {
    let errors = {};
    if (values.name === "") {
      errors.name = "This field is required";
    }
    // reverted spectora changes
    // if (basedOn === null && isEdit === "duplicate" && isEdit !== "create") {
    if (basedOn === null) {
      errors.basedOn = "This field is required";
    }
    if (InspType === null) {
      errors.InspType = "This field is required";
    }
    if (values.report_title === "") {
      errors.report_title = "This field is required";
    }
    return errors;
  };

  const handleformSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));
    setDataIsCorrect(true);
  };

  const deleteTemplate = (id) => {
    const formdata = { template_id: id };
    RemoveTemplate((response) => {
      if (response.data.success) {
        toast.success(response.data.message);
        CloseDeleteModal();
        navigate("/templates");
      } else {
        console.error("else", response.data.message);
      }
    }, formdata);
  };

  const AddTemplate = () => {
    if (Object.keys(errors).length !== 0 && !dataIsCorrect) {
      return;
    }
    setLoadingToggle(true);
    CreateNewTemplate(
      (response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          load();
          handleClear();

          if (setTemplateDetails !== undefined) {
            setTemplateDetails((prev) => {
              const newDetails = {...prev};
              newDetails.description = values.description;
              newDetails.name = values.name;

              return newDetails;
            });
          }
          setLoadingToggle(false);
          // window.location.reload();
          navigate(`/templates/${response.data.data.name?.charAt(0) === "#" || response.data.data.name?.charAt(0) === "/" ? response.data.data.name?.substring(1).replace(new RegExp("/", "g"), "") : response.data.data.name.replace(new RegExp("/", "g"), "")}`, {
            state: {
              id: response.data.data.id,
              name: response.data.data.name,
              edit: true,
            },
          });
          // navigate(`/templates/${response.data.data.name.replace(new RegExp("/", "g"), "")}`, { state: { id: response.data.data.id, name: response.data.data.name, edit: true } });
          // navigate("/templates");
        } else {
          toast.error(response.data.message);
        }
      },
      {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        name: values.name,
        description: values.description,
        report_title: values.report_title,
        inspection_type_id: InspType.id,
        based_on: basedOn?.id,
        default: 0,
        is_active: 0,
        line_level_rating: 0,
      }
    );
  };

  const UpdateTemplate = () => {
    if (Object.keys(errors).length !== 0 && !dataIsCorrect) {
      return;
    }
    setLoadingToggle(true);
    UpdateTemplateReq(
      (response) => {
        if (response.data.success) {
          // toast.success(response.data.message);
          // window.location.reload();
          load();
          // navigate("/templates");
          setLoadingToggle(false);
          setTemplateDetails((prev) => {
            const newDetails = {...prev};
            newDetails.description = values.description;
            newDetails.name = values.name;

            return newDetails;
          });
          handleClear();
        } else {
          toast.error(response.data.message);
        }
      },
      {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        template_id: templateId,
        name: values.name,
        description: values.description,
        report_title: values.report_title,
        inspection_type_id: InspType?.id ?? null,
        default: !isDefault ? 0 : 1,
        is_active: 0,
        line_level_rating: 0,
      }
    );
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      isEdit === "edit" ? UpdateTemplate() : AddTemplate();
    }
  }, [errors]);

  const payload = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    template_id: templateId,
  };

  const FetchDetails = () => {
    setLoadingToggle(true);
    GetTemplateDetails();
    GetExistingTemplates((response) => {
      const { data, success, message } = response.data;
      if (success) {
        setValues({
          name: data.name,
          description: data.description,
          report_title: data.report_title,
        });
        setIsDefault(data.default === 0 ? false : true);
        setBasedOn({ id: data.based_on.id, name: data.based_on.name });
        typeDetails.forEach((value) => {
          if (value.id === data.inspection_type_id) {
            setInspType({ id: value.id, name: value.name });
          }
        });
        setLoadingToggle(false);
        // if (data.inspection_type_id !== null) {
        //   templates?.map((temp) => {
        //     if (data.inspection_type_id === temp.id) {
        //       setInspType(temp);
        //     }
        //   });
        // }

        // if (data.based_on !== null) {
        //   templates?.map((temp) => {
        //     if (data.based_on === temp.id) {
        //       setBasedOn(temp);
        //     }
        //   });
        // }
      } else {
        console.error("else", message);
      }
    }, payload);
  };
  const spacingRemove = (e) => {
    if (e.which === 32 && !e.target.value.length) {
      e.preventDefault();
    } else if (/[^\w\s]/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    isEdit === "edit" && FetchDetails();
  }, [refresh]);

  // useEffect(() => {
  //   if(open && isEdit === "edit"){
  //     GetTemplateDetails()

  //   }

  // }, [open && isEdit === "edit"]);

  useEffect(() => {
    if (sessionStorage.getItem("company-key") &&
        sessionStorage.getItem("company-key") !== "null") GetTemplateDetails();
  }, [deleteControl]);

  return (
    <>
      <Modal open={open} onClose={() => handleClear()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ top: "30%" }}>
          <Grid className={"inspection-filter-modal-grid-main"} container spacing={2}>
            <Grid item xs={8}>
              <h3
                style={{
                  marginLeft: "0px",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
                className={"inspection-filter-modal-title"}
              >
                {/*   revert spectora changes */}
                {/* {isEdit === "create" ? "New blank template" : isEdit === "duplicate" ? "Duplicate existing template" : "Edit template settings"} */}
                {isEdit === "create" ? "New template" : isEdit === "duplicate" ? "Duplicate existing template" : "Edit template settings"}
              </h3>
            </Grid>
            <Grid item xs={4}>
              <CloseIcon onClick={() => handleClear()} className={"inspection-filter-modal-close-icon"} />
            </Grid>
            <Grid container style={{ paddingTop: "28px" }} spacing={2} className="inspection-filter-modal-margins">
              {/* reverted spectora changes */}
              {/* {isEdit === "duplicate" || (isEdit === "edit" && basedOn) ? ( */}
              <Grid item lg={6} xs={11.4} sm={11.4}>
                <FormControl fullWidth>
                  <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                    BASED ON*
                    <span>
                      <BootstrapTooltip title={<h1 className="font-13">Based on template</h1>}>
                        <InfoOutlinedIcon sx={{ position: "relative", top: "-4px" }} className="info-icon" />
                      </BootstrapTooltip>
                    </span>
                  </label>
                  <Autocomplete className={"inspection-filter-modal-select custom-color arrow-style "} id="tags-outlined" options={basedDetails} groupBy={(option) => option.id} filterSelectedOptions size="small" classes={{ groupLabel: "auto-complete-basedon" }} value={basedOn} getOptionLabel={(option) => option.name} onChange={(e, newValue) => setBasedOn(newValue)} disabled={isEdit === "edit" ? true : false} renderInput={(params) => <TextField {...params} placeholder="Select a template" error={errors.basedOn} helperText={errors.basedOn} />} />
                </FormControl>
              </Grid>
              {/* ) : null} */}
              <Grid
                item
                // reverted spectora changes
                // lg={isEdit === "create" || (isEdit === "edit" && !basedOn) ? 11.55 : isEdit === "duplicate" ? 5.5 : 6}
                lg={5.5}
                xs={11.4}
                sm={11.4}
                style={{ paddingLeft: "15px" }}
              >
                <FormControl fullWidth>
                  <label className="inspection-filter-modal-label " htmlFor="Inspectors" id="demo-simple-select-label">
                    TYPE OF INSPECTION*
                  </label>
                  <Autocomplete
                    className={`${isEdit !== "create" ? (isEdit === "edit" && basedOn ? "inspection-filter-modal-select" : "") : ""} custom-color arrow-style`}
                    fullWidth
                    id="tags-outlined"
                    options={typeDetails}
                    filterSelectedOptions
                    size="small"
                    value={InspType}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => {
                      setInspType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="Select type" error={errors.InspType} helperText={errors.InspType} />}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: "34px" }} spacing={2} className="inspection-filter-modal-margins">
              <Grid item lg={6} xs={11.4} sm={5.8}>
                <FormControl fullWidth>
                  <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                    NAME*
                  </label>
                  <TextField onKeyDown={(e) => spacingRemove(e)} style={{ paddingTop: "0px !important" }} type="text" size="small" name="name" variant="outlined" placeholder="Name to help inspector identify template" className={"inspection-filter-modal-select"} onChange={handleChange} value={values.name} error={errors.name} helperText={errors.name} />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={11.4} sm={5.7} style={{ paddingLeft: "15px" }}>
                <FormControl fullWidth>
                  <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                    REPORT TITLE*
                  </label>
                  <TextField type="text" size="small" name="report_title" variant="outlined" placeholder="Name that will appear on report" className={"inspection-filter-modal-select"} onChange={handleChange} value={values.report_title} error={errors.report_title} helperText={errors.report_title} />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container style={{ paddingTop: "34px" }} spacing={2} className="inspection-filter-modal-margins">
              <Grid item lg={11.55} xs={11.4} sm={11.4}>
                <FormControl fullWidth>
                  <label className="inspection-filter-modal-label" htmlFor="Inspectors" id="demo-simple-select-label">
                    DESCRIPTION
                  </label>
                  <TextField rows={5} fullWidth multiline style={{ paddingTop: "0px !important" }} type="text" size="small" name="description" variant="outlined" placeholder="Internal description for your template." onChange={handleChange} value={values.description} />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: isEdit === "edit" ? "space-between !important" : "flex-end !important",
              }}
              className="inspection-filter-modal-buttons1"
            >
              {isEdit === "edit" && (
                <Button onClick={() => OpenDeleteModal(values.name)} className="delete_template_btn" startIcon={<DeleteIcon />}>
                  Delete template
                </Button>
              )}

              <div className="edit_temp_btn">
                <Button variant="outlined" sx={{ ":hover": { backgroundColor: "transparent" } }} className="inspection-filter-modal-buttons-cancel" onClick={() => handleClear()}>
                  Cancel
                </Button>
                <LoadingButton loading={loadingToggle} variant="contained" className="inspection-filter-modal-buttons-apply" onClick={handleformSubmit}>
                  {isEdit === "edit" ? "Save" : "Create"}
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={deleteModal} onClose={CloseDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "655px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={CloseDeleteModal} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mt: "90px" }}>
              <img src={AlertImage} alt="alert" style={{ width: "51px", height: "49px" }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Delete template <b style={{ fontSize: "22px", wordWrap: "break-word" }}>{name} </b>?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Deleting a template will permanently remove it from all locations.
                <br /> Previous reports will not be affected. This cannot be undone.{" "}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: 8,
                mr: "34px",
                pb: "25px",
              }}
            >
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px" }} onClick={CloseDeleteModal}>
                Cancel
              </Button>
              <Button variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3 }} onClick={() => deleteTemplate(templateId)}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EditTemplate;
