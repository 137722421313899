// File imports
import PropTypes from 'prop-types';
import CLColorPicker from './CLColorPicker';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import { apiCall, apiCallGet, apiCallPut } from '../../../utils/action';
import Preloader from '../../../helpers/Preloader';
import SearchIcon from "@mui/icons-material/Search";
import CLSummaryButtonGroup from './CLSummaryButtonGroup';
import { timestamp } from '../../../utils/services';
import { stableSort } from '../CommentLibraryHelper';
import CLDeleteSummaryConfirm from './CLDeleteSummaryConfirm';
import React, { useEffect, useMemo, useState } from 'react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Paper, Button, Box, Grid, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, IconButton, TableHead, TableSortLabel } from '@mui/material';
import AddNewSummary from './AddNewSummary';

function TabPanel(props) {
    // Destructuring props
    const { children, value, index, ...other } = props;
    // JSX
    return (
        <div
            role="tabpanel"
            hidden={value !== index} // Hides the tab panel when it's not selected
            id={`simple-tabpanel-${index}`} // Generates a unique ID for each tab panel
            aria-labelledby={`simple-tab-${index}`} // The ID of the tab
            {...other} // Spreads any other properties
        >
            {/* Renders the tab panel when it's selected */}
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography> {/* Renders the tab's content */}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node, // The content of the tab
    index: PropTypes.number.isRequired, // The index of the tab
    value: PropTypes.number.isRequired, // The selected tab
};
/**
 * Creates a comparator function for sorting rows.
 *
 * @param {string} order - The sort order, either 'asc' or 'desc'
 * @param {string} orderBy - The property to sort by
 * @returns {function} - The comparator function
 */
function getComparator(order, orderBy) {
    /**
     * Comparator function that compares two rows based on the orderBy property
     *
     * @param {object} a - The first row
     * @param {object} b - The second row
     * @returns {number} - A negative number if a < b, positive if a > b, or 0 if equal
     */
    const descendingComparator = (a, b, orderBy) => {
        // If the property is a string, lowercase both values to compare
        if (typeof a[orderBy] === 'string') {
            const lowercaseA = a[orderBy].toLowerCase();
            const lowercaseB = b[orderBy].toLowerCase();

            if (lowercaseA < lowercaseB) {
                return -1;
            } else if (lowercaseA > lowercaseB) {
                return 1;
            }
        }
        // If the property is a number, compare normally
        if (a[orderBy] < b[orderBy]) {
            return -1;
        } else if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        // Items are equal
        return 0;
    };
    // Based on the order, sort the rows in either ascending or descending order
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const CLSummary = () => {
    // state hooks
    const [order, setOrder] = useState(''); // asc or desc order
    const [selected, setSelected] = useState([]); // selected rows
    const [orderBy, setOrderBy] = useState('calories'); // currently ordered by column
    const [showLoader, setShowLoader] = useState(false); // show loader indicator
    const [commentListData, setCommentListData] = useState([]); // comment list data
    const [initialTableData, setInitialTableData] = useState([]); // initial table data to perform search
    const [commentListInititalData, setCommentListInitialData] = useState([]); // initial comment list data
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [openAddNewSummaryModal, setOpenAddNewSummaryModal] = useState(false);
    const [deletedSummaryId, setDeletedSummaryId] = useState("");
    const handleOpenConfirmationModal = (row) => {
        setDeleteConfirmationModal(true);
        setDeletedSummaryId(row?.id);
    }
    const handleCloseConfirmationModal = () => setDeleteConfirmationModal(false);

    const handleOpenAddNewSummaryModal = () => setOpenAddNewSummaryModal(true);
    const handleCloseAddNewSummaryModal = () => setOpenAddNewSummaryModal(false);
    // API calls
    const GetCLSummaryList = () => {
        /**
         * Fetch comment list data from API
         * @param {number} pageNumber current page number
         */
        setShowLoader(true);
        apiCallGet(
            (response) => {
                const { data, message, success } = response.data;
                if (success) {
                    setCommentListData(data);
                    setInitialTableData(data)
                    setCommentListInitialData(data);
                    setShowLoader(false);
                } else {
                    console.log("else", message);
                }
            },
            "",
            "GET_COMMENT_LIB_SUMMARY_LIST"
        );
    };

    // componentDidMount equivalent using useEffect
    useEffect(() => {
        if (sessionStorage.getItem('company-key')) {
            GetCLSummaryList();
        }
    }, [sessionStorage.getItem('company-key')]);

    const handleSearchFilter = (event) => {
        /**
         * Handle search functionality
         * @param {string} event search field value
         */
        const query = event.toLowerCase();
        if (query === "") {
            setCommentListData(commentListInititalData);
        } else {
            const searchData = initialTableData?.filter((item) => {
                return (item?.title?.toLowerCase()?.includes(query));
            });
            setCommentListData(searchData);
        }
    };
    // Handle head cells, sorting and selection
    const EnhancedTableHead = (props) => {
        /**
         * Handle table head cells, sorting and selection
         * @param {object} props table head props
         */
        // eslint-disable-next-line
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow sx={{ height: "56px" }}>
                    {headCells?.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className='cl-summary-table-head'
                            sx={{ padding: "14px !important", fontFamily: "Source Sans Pro" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={() =>
                                    (headCell.id !== "order" && headCell.id !== "global" && headCell.id !== "color") ? (
                                        <UnfoldMoreIcon className='cl-summary-sort-icon' />
                                    ) : (
                                        ""
                                    )
                                }
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const headCells = [
        {
            id: 'order',
            numeric: false,
            disablePadding: false,
            label: "  ",
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Name',
        },

        {
            id: 'color',
            numeric: false,
            disablePadding: false,
            label: 'Color',
        },
        {
            id: 'times_used',
            numeric: false,
            disablePadding: false,
            label: 'Times used',
        },
        {
            id: 'global',
            numeric: false,
            disablePadding: false,
            label: '',
        },

    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        // togging the order for next time 
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const selectedComment = commentListData?.map((n) => n.id);
            setSelected(selectedComment);
            return;
        }
        setSelected([]);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(
        () =>
            stableSort(commentListData, getComparator(order, orderBy)),
        [order, orderBy, commentListData],
    );
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            commentListData,
            result.source.index,
            result.destination.index
        );
        items.forEach((element, i) => {
            element.order = i + 1;
            element.updated_at = timestamp();
            return element
        });
        setCommentListData(items);
    };

    const handleSummaryBlur = async (event, row) => {
        const updatedSummaryValue = event.target.value;
        const payload = {
            "summary_id": row?.id,
            "name": updatedSummaryValue,
            "definition": row?.definition,
            "color": row?.color
        };
        try {
            const response = await apiCallPut(
                (response) => {
                    const { success, message } = response.data;
                    if (success) {
                        GetCLSummaryList();
                    } else {
                        console.error("Update failed:", message);
                    }
                },
                payload,
                "EDIT_COMMENT_LIB_SUMMARY_LIST"
            );
            if (response?.data?.success) {
                const updatedData = commentListData.map((summary) =>
                    summary.id === row.id ? { ...summary, title: updatedSummaryValue } : summary
                );
                setCommentListData(updatedData);
            }
        } catch (error) {
            console.error("Error updating summary:", error);
        }
    };


    const handleColorChange = (id, color) => {
        const updatedRows = commentListData.map((row) =>
            row.id === id ? { ...row, color: color } : row
        );
        setCommentListData(updatedRows);
    };

    return (
        <>
            <Preloader showPreloader={showLoader} />
            <Box sx={{ width: '100%', padding: "30px", paddingBottom: "0px" }}>
                <Grid container className='cl-search-container'>
                    <Grid item xs={6} md={6} lg={2} className='cl-search-bar'>
                        <div className="cl-search">
                            <TextField
                                id="search-bar"
                                onChange={(e) => handleSearchFilter(e.target.value)}
                                variant="outlined"
                                placeholder="Search in table"
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '36px',
                                        width: '216px',
                                        borderColor: "#D5D5D5 !important",
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ccc",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#ccc",
                                        },
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                        fontSize: '16px !important',
                                        fontFamily: "Source Sans Pro !important",
                                    }
                                }}

                            />
                            <SearchIcon className="cl-summary-search-icon" />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} lg={10} sx={{ gap: "5px" }}>
                        <Button startIcon={<AddIcon />} className='add-new-btn' onClick={handleOpenAddNewSummaryModal} sx={{ width: "104px !important", backgroundColor: "#0F75BC !important", fontWeight: 600 }}><span>Add new</span></Button>
                    </Grid>
                </Grid>
                <Paper  sx={{ width: "100%", mb: 2, border: "1px solid #D5D5D5", borderRadius: "3px" }} className="cl-table" elevation={0}>
                    <TableContainer className="cl-table-container">
                        <Table aria-label="simple table">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={commentListData.length}
                            />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        
                                        <TableBody
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {visibleRows.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                // will be use in upcoming release
                                                // <Draggable
                                                //     key={row.id}
                                                //     draggableId={"q-" + row.id}
                                                //     index={index}
                                                // >
                                                //     {(provided) => (
                                                        <TableRow
                                                            hover
                                                            tabIndex={1}
                                                            key={row.id}
                                                            ref={provided.innerRef}
                                                            selected={isItemSelected}
                                                            sx={{ cursor: 'pointer' }}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            aria-checked={isItemSelected}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                size='small'
                                                                padding="none"
                                                                sx={{ padding: "0px 8px 0px 15px", width: "4%" }}
                                                                className='cl-row-status cl-drag-col'
                                                            >
                                                                <IconButton>
                                                                    <DragIndicatorIcon sx={{ color: '#878787' }} fontSize="small" />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell sx={{ padding: "6px 6px 6px 0px" }} padding="none" className='cl-title-col' align="left">
                                                                <TextField className="summary-text-field" style={{ width: "100%", minWidth: "900px"}}
                                                                    defaultValue={row.title}
                                                                    onBlur={(e) => handleSummaryBlur(e, row)}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ padding: "0px 8px 5px 8px" }} padding='none' className='cl-color-col' align="left" style={{ width: "200px", position: "relative" }}>
                                                                <CLColorPicker row={row} onColorChange={handleColorChange} GetCLSummaryList={GetCLSummaryList} />
                                                            </TableCell>
                                                            <TableCell sx={{ padding: "0px 0px 5px 15px" }} padding='none' className='cl-times-used-col' align="left">
                                                                <span className='times-used-text'>{row?.times_used}</span>
                                                            </TableCell>
                                                            <TableCell padding='none' align='right' width="150px" sx={{ position: "relative" }}>
                                                                <CLSummaryButtonGroup handleOpenConfirmationModal={handleOpenConfirmationModal} row={row} />
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                // will be use in upcoming release
                                                // </Draggable>
                                                // )}
                                            )}
                                            {provided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </TableContainer>
                </Paper>
                <CLDeleteSummaryConfirm deleteConfirmationModal={deleteConfirmationModal} handleCloseConfirmationModal={handleCloseConfirmationModal} deletedSummaryId={deletedSummaryId} setDeletedSummaryId={setDeletedSummaryId} setDeleteConfirmationModal={setDeleteConfirmationModal} GetCLSummaryList={GetCLSummaryList} />
                <AddNewSummary open={openAddNewSummaryModal} handleClose={handleCloseAddNewSummaryModal} setOpenAddNewSummaryModal={setOpenAddNewSummaryModal} GetCLSummaryList={GetCLSummaryList} />
            </Box >
        </>
    )
}

export default CLSummary;