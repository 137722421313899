export const TemplateConstants = [
  {
    id: 0,
    heading: "Others Present",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["Buyer", "seller", "Building", "wood", "none"],
  },
  {
    id: 1,
    heading: "Property Occupied",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["Occupied", "Vaccant"],
  },
  {
    id: 2,
    heading: "Building Type",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["Single Family", "Condo", "Townhouse", "Duplex", "Multi-Family"],
  },
  {
    id: 3,
    heading: "Weather",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["Clear", "Sunny", "Partly Cloudy", "Rain", "Sleet", "Snow"],
  },
  {
    id: 4,
    heading: "Weather",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["Clear", "Sunny", "Partly Cloudy", "Rain", "Sleet", "Snow"],
  },
  {
    id: 5,
    heading: "Temperature",
    Type: "temprature",
    description: "Numeric input. Select Farenheit or Celcius as default.  ",
  },
  {
    id: 6,
    heading: "Year Built",
    Type: "textInput",
    description: "Text input. Use for short answer information ",
  },
  {
    id: 7,
    heading: "Water Source",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["City", "Well", "Cistern"],
  },
  {
    id: 8,
    heading: "Sewage/Disposal",
    Type: "checks",
    description:
      "Answer choices. Selected items will appear checked in new inspections by default.",
    checks: ["City", "Septic"],
  },
];

export const NewSiteList = [
  {
    id: 1,
    name: "Checkboxes",
  },
  {
    id: 2,
    name: "Text input",
  },
  {
    id: 3,
    name: "Temperature",
  },
];
