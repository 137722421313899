import React from 'react'
import Box from "@mui/material/Box";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
// common components imports
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import AgreementEditor from '../../../components/AgreementsDetails/AgreementEditor';
import { Typography } from '@mui/material';
import{colors} from '../../../config/app-constants';
import { useEffect,useState } from 'react';
import { apiCall } from '../../../utils/action';
import toast from 'react-hot-toast';
const AgreementsDetails = () => {
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  const [agreementStatus,setAgreementStatus]= useState(1)
  const TypeCheck = (types) => {
    if (types === "error") {
      var color = colors["FEES_OWED"],
        bg = colors["ERROR_BG"];
      return { color, bg };
    } else if (types === "success") {
      // condition
    }
  };
  const navigate= useNavigate();

  const getAgreementStatus=()=>{
    toast.dismiss();
    const formdata = { company_id: companyId, user_id:uuId, role_id:roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {         
          setAgreementStatus(data.agreement?.agreement_status)
        } else {
         toast.error(message)
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  }
  useEffect(() => {
   getAgreementStatus();   
  }, [])
  

  return (
    <>    
    {window.scrollTo(0, 0)}    
      <Box sx={{ flexGrow: 1 }} className="page-background">
       <Header/>
       {agreementStatus=== 0 &&
 <div
      style={{
        width: "100%",
       marginTop:'127px'
      }}
    >
     <Box
        sx={{
          display: "flex",
          py: "14px",
          px: { xs: "10px", sm: "80px", md: "80px", lg: "80px" },
          backgroundColor: TypeCheck('error').bg,
          color: TypeCheck('error').color,
          fontSize: "14px",
          alignItems: "center",
        }}
      >
        {<WarningAmberIcon
                sx={{
                  color: "#FF9800",
                  mr: "12px",
                  width: "22px",
                  height: "22px",
                }}
              />}
        <Typography className='agreement-notify'><span style={{color:'#454545',fontSize:'14px'}}>Agreements are currently turned off. This agreement will not be sent. </span><span className='agreement_navigate' onClick={()=>navigate('/settings/agreements')}> Turn agreements on <ArrowForwardIosIcon/></span></Typography>
      </Box>
    </div>
}
      
       <Breadcrumb alertPresent={agreementStatus=== 0 ?true:false}/>
         <AgreementEditor />
      </Box>
    </>
  )
}

export default AgreementsDetails