import { Button, Collapse, Grid } from "@mui/material";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CollapseSection = (props) => {
  const [expanded, setExpanded] = useState(props.IsExpanded ? true : false);
  const handleExpanded = () => setExpanded(!expanded);
  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="text"
          sx={{
            color: expanded ? "#4282E2" : "#3b3b3b",
            fontFamily: "Open Sans",
            fontSize: "15px",
            fontWeight: "bold",
            letterSpacing: 0,
            ml:0,
            lineHeight: "30px",
          }}
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={handleExpanded}
        >
          {props.title}
        </Button>{" "}
      </Grid>
      <Collapse in={expanded}>
        <>{props.description}</>
      </Collapse>
    </>
  );
};

export default CollapseSection;
