import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const SignatureNeededTemplate = () => {
  return (
    <>
    <EmailTemplate title='Reminder to Sign Agreement '
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='signature_needed_reminder'
    />
    </>
  )
}

export default SignatureNeededTemplate