import React from 'react'
import { useState } from 'react';
import Preloader from '../../helpers/Preloader';
import EditTemplate from './EditTemplate';


const TemplateDetails = (props) => {
  const {templateId,templateName} = props;
  const [showpreloader, setShowpreloader] = useState(false);

  return (
    <>
    <Preloader showPreloader={showpreloader} />
    <div style={{backgroundColor:'#f7f9fa'}} className="inspection-details">
        <EditTemplate templateId={templateId} templateName={templateName}/>
    </div>
    </>
  )
}

export default TemplateDetails