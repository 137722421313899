import Box from "@mui/material/Box";
// common components imports
import Breadcrumb from "../../common/header/Breadcrumb";
import Header from "../../common/header/Header";import PaymentMadeTemplate from '../../components/NotificationSettings/PaymentMadeTemplate'
import PaymentRequestTemplate from "../../components/NotificationSettings/PaymentRequestTemplate";

const PaymentRequest = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <PaymentRequestTemplate/>
      </Box>
    </>
  )
}

export default PaymentRequest