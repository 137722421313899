import "../../../assets/styles/containers/_commentTags.scss";
/**
 * Component for rendering a list of tags for a comment
 */
const CommentTags = ({ tags }) => {
  /**
   * Returns text color based on the specified background color.
   *
   * @param {string} bgColor - The background color as a hex string
   * @returns {string} The text color as a hex string
   */
  const getTextColor = (bgColor) => {
    const luminance = getLuminance(bgColor);
    return luminance > 0.179 ? "#000000" : "#ffffff";
  };

  /**
   * Calculates the relative luminance of a color.
   *
   * @param {string} hexColor - The color as a hex string
   * @returns {number} The relative luminance in the range [0, 1]
   */
  function getLuminance(hexColor) {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);

    const Y = (r * 299 + g * 587 + b * 114) / 1000;
    return Y <= 0.03928 ? Y / 12.92 : ((Y + 0.055) / 1.055) ** 2.4;
  }

  // /**
  //  * Calculates the contrast ratio between two colors.
  //  *
  //  * @param {string} fg - The foreground color as a hex string
  //  * @param {string} bg - The background color as a hex string
  //  * @returns {number} The contrast ratio
  //  */
  // const getContrast = (fg, bg) => {
  //     const l1 = getLuminance(fg);
  //     const l2 = getLuminance(bg);
  //     return (Math?.max(l1, l2) + 0.05) / (Math?.min(l1, l2) + 0.05);
  // }

  return (
    <div className="comment_tags_container">
      {tags?.map((tag, index) => (
        <div
          key={index}
          className="comment_tag"
          style={{
            backgroundColor: tag?.tag?.color || tag?.color || "#000000",
            // Not implementing dynamic contrast in Phase 1
            color: getTextColor("#000000"),
          }}
        >
          {tag?.tag?.name || tag?.name || tag?.title}
        </div>
      ))}
    </div>
  );
};

export default CommentTags;
