// File imports
import PropTypes from 'prop-types';
import AddNewTag from './AddNewTag';
import CLColorPicker from './CLColorPicker';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import Preloader from '../../../helpers/Preloader';
import SearchIcon from "@mui/icons-material/Search";
import CLTagsButtonGroup from './CLTagsButtonGroup';
import { timestamp } from '../../../utils/services';
import { stableSort } from '../CommentLibraryHelper';
import CLDeleteTagsConfirm from './CLDeleteTagsConfirm';
import React, { useEffect, useMemo, useState } from 'react';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { apiCallGet, apiCallPut } from '../../../utils/action';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Paper, Button, Box, Grid, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, IconButton, TableHead, TableSortLabel } from '@mui/material';
/**
 *
 * TabPanel
 *
 * Renders a tab panel for the Comment Library
 */
function TabPanel(props) {
    // Destructuring props
    const { children, value, index, ...other } = props;
    // JSX
    return (
        <div
            role="tabpanel"
            hidden={value !== index} // Hides the tab panel when it's not selected
            id={`simple-tabpanel-${index}`} // Generates a unique ID for each tab panel
            aria-labelledby={`simple-tab-${index}`} // The ID of the tab
            {...other} // Spreads any other properties
        >
            {/* Renders the tab panel when it's selected */}
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography> {/* Renders the tab's content */}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node, // The content of the tab
    index: PropTypes.number.isRequired, // The index of the tab
    value: PropTypes.number.isRequired, // The selected tab
};
/**
 * Creates a comparator function for sorting rows.
 *
 * @param {string} order - The sort order, either 'asc' or 'desc'
 * @param {string} orderBy - The property to sort by
 * @returns {function} - The comparator function
 */
function getComparator(order, orderBy) {
    /**
     * Comparator function that compares two rows based on the orderBy property
     *
     * @param {object} a - The first row
     * @param {object} b - The second row
     * @returns {number} - A negative number if a < b, positive if a > b, or 0 if equal
     */
    const descendingComparator = (a, b, orderBy) => {
        // If the property is a string, lowercase both values to compare
        if (typeof a[orderBy] === 'string') {
            const lowercaseA = a[orderBy].toLowerCase();
            const lowercaseB = b[orderBy].toLowerCase();

            if (lowercaseA < lowercaseB) {
                return -1;
            } else if (lowercaseA > lowercaseB) {
                return 1;
            }
        }
        // If the property is a number, compare normally
        if (a[orderBy] < b[orderBy]) {
            return -1;
        } else if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        // Items are equal
        return 0;
    };
    // Based on the order, sort the rows in either ascending or descending order
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
/**
 * Component for displaying Comment Library table
 */
const CLTags = () => {
    // state hooks
    const [order, setOrder] = useState('asc'); // asc or desc order
    const [selected, setSelected] = useState([]); // selected rows
    const [orderBy, setOrderBy] = useState('calories'); // currently ordered by column
    const [showLoader, setShowLoader] = useState(false); // show loader indicator
    const [deletedTagId, setDeletedTagId] = useState(""); //id for delete tag
    const [commentListData, setCommentListData] = useState([]); // comment list data
    const [initialTableData, setInitialTableData] = useState([]); // initial table data to perform search
    const handleopenAddNewTagModal = () => setopenAddNewTagModal(true); //modal toggle 
    const [openAddNewTagModal, setopenAddNewTagModal] = useState(false);
    const handleCloseAddNewTagModal = () => setopenAddNewTagModal(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [commentListInititalData, setCommentListInitialData] = useState([]); // initial comment list data
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const handleOpenConfirmationModal = (row) => { setDeleteConfirmationModal(true); setDeletedTagId(row) }
    const handleCloseConfirmationModal = () => setDeleteConfirmationModal(false);
    // API calls
    const GetCommentLibTagList = () => {
        /**
         * Fetch comment list data from API
         * @param {number} pageNumber current page number
         */
        setShowLoader(true);
        apiCallGet(
            (response) => {
                const { data, message, success } = response.data;
                if (success) {
                    setCommentListData(data);
                    setInitialTableData(data)
                    setCommentListInitialData(data);
                    setShowLoader(false);
                } else {
                    console.log("else", message);
                }
            },
            "",
            "GET_COMMENT_LIB_TAGS_LIST"
        );
    };
    // Edit Tag(s)
    const handleTagTitleChange = async (event, row) => {
        const updatedTagValue = event.target.value;
        const payload = {
            "tag_id": row?.id,
            "name": updatedTagValue,
            "color": row?.color
        };
        try {
            const response = await apiCallPut(
                (response) => {
                    const { success, message } = response.data;
                    if (success) {
                        GetCommentLibTagList();
                    } else {
                        console.error("Update failed:", message);
                    }
                },
                payload,
                "EDIT_TAGS_LIST"
            );
            if (response?.data?.success) {
                const updatedData = commentListData.map((tag) =>
                    tag.id === row.id ? { ...tag, title: updatedTagValue } : tag
                );
                setCommentListData(updatedData);
            }
        } catch (error) {
            console.error("Error updating summary:", error);
        }
    };

    // componentDidMount equivalent using useEffect
    useEffect(() => {
        if (sessionStorage.getItem('company-key')) {
            GetCommentLibTagList();
        }
        // eslint-disable-next-line
    }, [sessionStorage.getItem('company-key')]);

    const handleSearchFilter = (event) => {
        /**
         * Handle search functionality
         * @param {string} event search field value
         */
        const query = event.toLowerCase();
        if (query === "") {
            setCommentListData(commentListInititalData);
        } else {
            const searchData = initialTableData?.filter((item) => {
                return (item?.title?.toLowerCase()?.includes(query));
            });
            setCommentListData(searchData);
        }
    };
    // Handle head cells, sorting and selection
    const EnhancedTableHead = (props) => {
        /**
         * Handle table head cells, sorting and selection
         * @param {object} props table head props
         */
        // eslint-disable-next-line
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells?.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className='cl-table-head'
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                IconComponent={() =>
                                    (headCell.id !== "order" && headCell.id !== "global" && headCell.id !== "color") ? (
                                        <UnfoldMoreIcon className='cl-sort-icon' />
                                    ) : (
                                        ""
                                    )
                                }
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const headCells = [
        {
            id: 'order',
            numeric: false,
            disablePadding: false,
            label: "  ",
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: 'Tag',
        },

        {
            id: 'color',
            numeric: false,
            disablePadding: false,
            label: 'Color',
        },
        {
            id: 'times_used',
            numeric: false,
            disablePadding: false,
            label: 'Times used',
        },
        {
            id: 'global',
            numeric: false,
            disablePadding: false,
            label: '',
        },

    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const selectedComment = commentListData?.map((n) => n.id);
            setSelected(selectedComment);
            return;
        }
        setSelected([]);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const visibleRows = useMemo(
        () =>
            stableSort(commentListData, getComparator(order, orderBy)),
        // eslint-disable-next-line
        [order, orderBy, commentListData],
    );
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            commentListData,
            result.source.index,
            result.destination.index
        );
        items.forEach((element, i) => {
            element.order = i + 1;
            element.updated_at = timestamp();
            return element
        });
        setCommentListData(items);
    };
    // color change
    const handleColorChange = (id, color) => {
        const updatedRows = commentListData.map((row) =>
            row.id === id ? { ...row, color: color } : row
        );
        setCommentListData(updatedRows);
    };

    // render JSX
    return (
        <>
            <Preloader showPreloader={showLoader} />
            <AddNewTag open={openAddNewTagModal} handleClose={handleCloseAddNewTagModal} setopenAddNewTagModal={setopenAddNewTagModal} GetCommentLibTagList={GetCommentLibTagList} />
            <Box sx={{ width: '100%', padding: "30px" }}>
                <Grid container className='cl-search-container'>
                    <Grid item xs={6} md={6} lg={2} className='cl-search-bar'>
                        <div className="cl-search">
                            <TextField
                                size="small"
                                id="search-bar"
                                variant="outlined"
                                placeholder="Search in table"
                                onChange={(e) => handleSearchFilter(e.target.value)}
                            />
                            <SearchIcon className="cl-search-icon" />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6} lg={10} sx={{ gap: "5px" }}>
                        <Button startIcon={<AddIcon />} className='add-new-btn' onClick={handleopenAddNewTagModal}><span>Add new</span></Button>
                    </Grid>
                </Grid>
                <Paper sx={{ width: '100%', mb: 2, border: "1px solid #D5D5D5", borderRadius: "3px" }} className='cl-table' elevation={0}>
                    <TableContainer
                        component={Paper}
                        style={{ maxHeight: '400px', overflow: 'auto' }}
                    >
                        <Table aria-label="simple table">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                rowCount={commentListData.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <TableBody
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {visibleRows.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        ref={provided.innerRef}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        aria-checked={isItemSelected}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            size="small"
                                                            padding="none"
                                                            sx={{ padding: "0px 20px 0px 20px" }}
                                                            className='cl-row-status cl-exp-col'
                                                        >
                                                            <IconButton>
                                                                <DragIndicatorIcon
                                                                    sx={{ color: '#878787', mt: -1 }}
                                                                    fontSize="small"
                                                                />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell
                                                            padding="none"
                                                            sx={{ p: "0px 10px 0px 10px" }}
                                                            className='cl-title-col'
                                                            align="left"
                                                        >
                                                            <TextField
                                                                className="cl_tags_textfield"
                                                                sx={{ mt: -1, mb: 1 }}
                                                                defaultValue={row.title}
                                                                onBlur={(e) => handleTagTitleChange(e, row)}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ padding: "10px 10px 0px 10px" }}
                                                            padding="none"
                                                            className='cl-global-col'
                                                            align="left"
                                                            style={{ width: "200px", position: "relative" }}
                                                        >
                                                            <CLColorPicker
                                                                row={row}
                                                                onColorChange={handleColorChange}
                                                                GetCommentLibTagList={GetCommentLibTagList}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{ padding: "0px 0px 5px 15px" }}
                                                            padding="none"
                                                            className='cl-rating-col'
                                                            align="left"
                                                        >
                                                            <span className='times-used-text'>{row?.times_used}</span>
                                                        </TableCell>
                                                        <TableCell
                                                            padding="none"
                                                            align="right"
                                                            width="160px"
                                                            sx={{ position: "relative" }}
                                                        >
                                                            <CLTagsButtonGroup
                                                                handleOpenConfirmationModal={handleOpenConfirmationModal}
                                                                row={row}
                                                                setDeletedTagId={setDeletedTagId}
                                                                deletedTagId={deletedTagId}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Table>
                    </TableContainer>
                </Paper>
                <CLDeleteTagsConfirm setDeleteLoader={setDeleteLoader} deleteLoader={deleteLoader} deleteConfirmationModal={deleteConfirmationModal} handleCloseConfirmationModal={handleCloseConfirmationModal} deletedTagId={deletedTagId} setDeletedTagId={setDeletedTagId} setDeleteConfirmationModal={setDeleteConfirmationModal} GetCommentLibTagList={GetCommentLibTagList} />
            </Box>
        </>
    )
}

export default CLTags;