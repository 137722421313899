import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function CopySummaryModal(props) {
  const { summaryCopyModalOpen, setSummaryCopyModalOpen, title, handleCopyComment } = props;

  const handleClose = () => {
    setSummaryCopyModalOpen(false);
  };

  return (
    <Dialog open={summaryCopyModalOpen} onClose={handleClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{ display: "flex", justifyContent: "center", pb: 0 }}>
        <DialogTitle className="category-dialog-title">{"Replace summary comment?"}</DialogTitle>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} display={"flex"} mb={2} justifyContent={"center"}>
            <Typography sx={{ fontSize: "18px !important", fontWeight: "600 !important", color: "#252525 !important" }}>{title}</Typography>
          </Grid>
          <DialogContentText sx={{ fontSize: "14px !important", fontWeight: "400 !important", color: "#252525 !important" }}>Are you sure you want to replace the summary comment?</DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container display={"flex"} mb={1.5} justifyContent={"center"} gap={2}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              className="delete_confirm_btn"
              variant="contained"
              onClick={handleCopyComment}
              sx={{
                backgroundColor: "#0F75BC !important",
                width: "320px !important",
                height: "36px !important",
                "&:hover": {
                  backgroundColor: "#0F75BC !important",
                },
              }}
            >
              REPLACE
            </Button>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button variant="text" className="delete_cancel_btn" disableRipple onClick={() => setSummaryCopyModalOpen(false)}>
              <Typography className="custom-underline">Cancel</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CopySummaryModal;
