import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const Label = ({ label, drilldownView, onDrillDown }) => {
    if (!drilldownView) {
        return <span>{label}</span>;
    }

    return <a onClick={onDrillDown}>{label}</a>;
};

Label.propTypes = {
    label: PropTypes.node,
    date: PropTypes.instanceOf(Date),
    drilldownView: PropTypes.string,
    onDrillDown: PropTypes.func,
    isOffRange: PropTypes.bool,
};

const DateHeader = ({ label, drilldownView, onDrillDown, isOffRange, date, currentDate }) => {
    return moment(currentDate)?.format('D') === label ? (
        <div>
            <div>
                <div className="calendar-current-day">
                    <Label {...{ label, drilldownView, onDrillDown, isOffRange }} />
                </div>
            </div>
        </div>
    ) : (
        <div>
            <div className="calendar-month-view-label" style={{ color: isOffRange ? '#ADB5BD' : '#32325D' }}>
                <Label {...{ label, drilldownView, onDrillDown, isOffRange }} />
            </div>
            {/* <div className="dh-item header-right"></div> */}
        </div>
    );
};

DateHeader.propTypes = {
    label: PropTypes.node,
    date: PropTypes.instanceOf(Date),
    drilldownView: PropTypes.string,
    onDrillDown: PropTypes.func,
    isOffRange: PropTypes.bool,
};

export default DateHeader;
