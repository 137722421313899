import React from 'react'

import Box from "@mui/material/Box";

// common components imports
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import SettingsTabs from '../../components/SettingsTabs/SettingsTabs';
import "../../../assets/styles/containers/_settings.scss";

const Settings = () => {
  return (
    <>
    {window.scrollTo(0, 0)}    
      <Box sx={{ flexGrow: 1 }} className="page-background">
       <Header/>
       <Breadcrumb/>   
       <SettingsTabs/>
      </Box>
      </>
  )
}

export default Settings