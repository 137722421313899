import React, { useEffect, useState, useRef } from "react";
import { API_CONSTANTS } from "../../config/api-constants";
// import "../../../assets/styles/containers/_inspDetails.scss";
import "../../../assets/styles/containers/_templates.scss";
import "../../../assets/styles/containers/_templates2.scss";
import Typography from "@mui/material/Typography";
import CategoryDetails from "./CategoryDetails";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import SettingsIcon from "@mui/icons-material/Settings";
import { useLocation, useNavigate } from "react-router-dom";
import TemplateAdd from "../../common/TemplateUtils/TemplateAdd";
import InspectionDetails from "./TemplateAccord/InspectionDetails";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import EditTemplateList from "../../components/templateListView/EditTemplateList";
import { getCommentLibrarySummaryTagList, getCommentLibraryTagList } from "../../store/action";
import { timestamp } from "../../utils/services";

const EditTemplate = (props) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { templateId, templateName } = props;
  const [CategoryList, setCategoryList] = useState([]);
  const [AllCategoryList, setAllCategoryList] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [basedDetails, setBasedDetails] = useState([]);
  const [typeDetails, setTypeDetails] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const handleCloseNew = () => setAddNew(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [loadDetails, setLoadDetails] = useState(0);
  const [load, setLoad] = useState(0);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [outsideClick, setOutsideClick] = useState(false);
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const [templateRatingDetails, setTemplateRatingDetails] = useState([]);
  const [summary, setSummary] = useState({ innerHTML: "" });
  const [newCatList, setNewCatList] = useState([]);
  const companyId = sessionStorage.getItem("company_id");
  const [renId, setRenId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState(0);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [updateTemplateCall, queueUpdateTemplateCall] = useState([]);
  const [updateTemplateData, queueUpdateTemplateData] = useState([]);
  const autoSaveRef = useRef(false);
  const AllCategoryListRef = useRef([]);
  const updateTemplateCallRef = useRef([]);
  const updateTemplateDataRef = useRef([]);
  const defaultRef = useRef(0);
  const autoSavingCheck = 30000; // every 30 seconds
  const dispatch = useDispatch();

  // moved here so we can control all updates in this component
  const [reRender, setReRender] = useState(false);
  const [action, setAction] = useState("");

  const checkActiveFocus = () => setIsFocused(true);

  const renamingId = (id) => {
    setRenId(id);
  };
  const version = location.state?.version;
  const getCategoryList = (loader) => {
    if (loader === "noLoader") {
      setShowLoader(false);
    } else setShowLoader(true);

    const formdata = {
      template_id: location.state?.id,
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
    };
    apiCall(
      (response) => {
        const { status_code, data, message, success } = response.data;
        if (success) {
          // setCategoryList(data?.template?.categories);
          setTemplateRatingDetails(data.template?.rating_data);
          setSummary({ innerHTML: data.template.summary_disclaimer || "" });
          setAllCategoryList(data);
          setShowLoader(false);
        } else {
          toast.error(message);
          if (status_code === 404) {
            navigate(`/templates/`);
            setShowLoader(false);
          }
        }
      },
      formdata,
      "GET_CATEGORY_LIST"
    );
  };

  const GetTemplateDetail = (loader) => {
    if (loader === "noLoader") {
      setShowLoader(false);
    } else setShowLoader(true);

    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      template_id: location.state?.id,
    };

    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setTemplateDetails(data);
          setDefaultTemplate(data.default);
        } else {
          navigate(`/templates/`);
        }
      },
      formdata,
      "TEMPLATE_DETAILS"
    );
  };

  const DiscardTemplateReq = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      template_id: location.state?.id,
    };

    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          navigate(`/templates/`);
          toast.success(message);
          setShowLoader(false);
        } else {
          console.error("else ", response.data.message);
        }
      },
      formdata,
      "DISCARD_TEMPLATE"
    );
  };

  const HandleOpenEditor = (type, open) => {
    setAddNew(open);
    setIsEdit(type);
    if (type === "edit") {
      setLoadDetails(loadDetails + 1);
    }
  };

  const PublishTemplate = () => {
    toast.dismiss();
    setShowLoader(true);
    AllCategoryList.user_id = uuId;
    AllCategoryList.company_id = companyId;
    AllCategoryList.role_id = roleId;
    setAutoSave(false);
    requestUpdate().then(() => {
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        template_id: location.state?.id,
      };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setShowLoader(false);
            toast.success(message);
            navigate(`/templates/`);
          } else {
            console.error("else ", response.data.message);
            toast.error(response.data.message);
          }
        },
        formdata,
        "PUBLISH_TEMPLATE"
      );
    });
  };

  const TogglerSwitch = (newValue) => {
    setDefaultTemplate(newValue);
    defaultRef.current = newValue;
    queueUpdateTemplateData((updateTemplateData) => [...updateTemplateData, {}]);
    updateTemplateDataRef.current = [...updateTemplateData, {}];
    setAutoSave(true);
  };

  const updateTemplate = (toastStatus, toastMessage, dynamicToastFlag, addTagToCategoryFunctionCall, successfulFunction = successfulCategoryChange) => {
    queueUpdateTemplateCall((updateTemplateCall) => [
      ...updateTemplateCall,
      {
        status: toastStatus,
        message: toastMessage,
        toastFlag: dynamicToastFlag,
        categoryFunctionCall: addTagToCategoryFunctionCall,
      },
    ]);
    if (successfulFunction) {
      successfulFunction();
    }
    updateTemplateCallRef.current = [
      ...updateTemplateCall,
      {
        status: toastStatus,
        message: toastMessage,
        toastFlag: dynamicToastFlag,
        categoryFunctionCall: addTagToCategoryFunctionCall,
      },
    ];
    setAutoSave(true);
  };

  const successfulCategoryChange = () => {
    setRenId("");
    setShowLoader(false);
    setOutsideClick(false);
    setIsRename(false);
    setIsFocused(false);
  };

  const successfulRatingChange = () => {
    setReRender(!reRender);
    setAction("");
  };

  const requestUpdateTemplateData = async (updatePayload, updateQueue) => {
    return new Promise((resolve, reject) => {
      updatePayload.user_id = uuId;
      updatePayload.company_id = companyId;
      updatePayload.role_id = roleId;
      updatePayload.updated_at = timestamp();

      if (updateQueue.length > 0) {
        queueUpdateTemplateCall([]);
        apiCall(
          (response) => {
            const { status_code, data, message, success } = response.data;
            if (success) {
              let foundCategoryFunctionCall = false;
              updateQueue.forEach((updateCall) => {
                if (updateCall.categoryFunctionCall) {
                  foundCategoryFunctionCall = true;
                  updateCall.categoryFunctionCall();
                  setTimeout(() => {
                    setLoad(load + 1);
                  }, 1000);
                }
              });
              if (!foundCategoryFunctionCall) setLoad(load + 1);
              resolve("finished");
            } else {
              toast.error(message);
              console.error("else ", message);
              if (status_code === 404) {
                navigate(`/templates/`);
              } else if (status_code === 400 && message === "You can't edit template details after published.") {
                navigate(`/templates/`);
              }

              reject(new Error(message));
            }
          },
          updatePayload,
          "UPDATE_TEMPLATE_DATA"
        );
      } else {
        resolve("finished");
      }
    });
  };

  const requestUpdateTemplateDetails = async (updatePayload, dataQueue) => {
    return new Promise((resolve, reject) => {
      if (dataQueue.length > 0) {
        queueUpdateTemplateData([]);
        apiCall(
          (response) => {
            const { status_code, data, message, success } = response.data;
            setIsAutoSaving(false);
            if (success) {
              setLoad(load + 1);
              resolve("finished");
            } else {
              toast.error(message);
              if (status_code === 404) {
                navigate(`/templates/`);
              } else if (status_code === 400 && message === "You can't edit template details after published.") {
                navigate(`/templates/`);
              }

              reject(new Error(message));
            }
          },
          {
            user_id: uuId,
            company_id: companyId,
            role_id: roleId,
            template_id: updatePayload?.template?.id,
            name: updatePayload?.template?.name,
            description: updatePayload?.template?.description,
            inspection_type_id: updatePayload?.template?.inspection_type_id,
            default: defaultRef.current,
            is_active: updatePayload?.template?.is_active,
            line_level_rating: updatePayload?.template?.line_level_rating,
          },
          "UPDATE_TEMPLATE_DETAILS"
        );
      } else {
        resolve("finished");
      }
    });
  };

  const requestUpdate = async () => {
    toast.dismiss();
    setIsAutoSaving(true);
    setAutoSave(false);
    const updateTemplateDataResult = await requestUpdateTemplateData(AllCategoryListRef.current, updateTemplateCallRef.current);
    const updateTemplateDetailsResult = await requestUpdateTemplateDetails(AllCategoryListRef.current, updateTemplateDataRef.current);

    if (updateTemplateDataResult === "finished" && updateTemplateDetailsResult === "finished") {
      toast.success("Your changes have been saved.");
    }

    setIsAutoSaving(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });

    const beforeUnload = (event) => {
      if (autoSaveRef.current) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message; // For most browsers
        return message; // For some older versions
      }
    };

    dispatch(getCommentLibraryTagList());
    dispatch(getCommentLibrarySummaryTagList());

    window.addEventListener("beforeunload", beforeUnload);

    return async () => {
      window.removeEventListener("beforeunload", beforeUnload);

      if (autoSaveRef.current) {
        await requestUpdate().then();
      }
    };
  }, []);

  useEffect(() => {
    autoSaveRef.current = autoSave;

    AllCategoryListRef.current = AllCategoryList;

    const saveCheckTimeoutId = setTimeout(() => {
      if (isAutoSaving === false && autoSaveRef.current === true) {
        requestUpdate();
      }
    }, autoSavingCheck);

    return () => {
      clearTimeout(saveCheckTimeoutId);
    };
  }, [autoSave]);

  const summaryEdit = () => {
    let newSummaryText = summary?.innerHTML?.replace("\n", "");
    AllCategoryList.template.summary_disclaimer = newSummaryText;
    updateTemplate("showToast", "Summary Disclaimer has been saved", true);
  };

  const LoadList = (loader) => {
    loader = load > 0 ? "noLoader" : loader;
    getCategoryList(loader);
    GetTemplateDetail(loader);
  };

  useEffect(() => {
    if (AllCategoryList?.template?.categories?.length > 0) {
      setCategoryList((prev) => {
        const newCategoryList = [...AllCategoryList?.template?.categories];
        return newCategoryList;
      });
    }
  }, [AllCategoryList]);

  useEffect(() => {
    if (load === 0) {
      LoadList();
    }
  }, [load]);

  return (
    <>
      <div className="detail-card" draggable="false">
        <Preloader showPreloader={showLoader} />
        <div style={{ marginTop: isMobile && "25px" }} className="layout">
          <Grid container className="templates">
            <Grid item xs={6}>
              <Typography className="layout__title">{templateDetails?.name?.length > 50 ? `${templateDetails?.name?.substring(50, 0)}...` : templateDetails?.name} </Typography>
            </Grid>
            <Grid item xs={6}>
              <SettingsIcon id="template_setting_btn" onClick={() => HandleOpenEditor("edit", true)} className="templates__icon" sx={{ float: "right" }} />
            </Grid>
            <Grid item xs={12}>
              <p className="layout__description">{templateDetails.description ?? ""}</p>
            </Grid>
          </Grid>
          <InspectionDetails
            isFocused={setIsFocused}
            newList={newCatList}
            setNewList={setNewCatList}
            checkActiveFocus={checkActiveFocus}
            renId={renId}
            setSkeletonLoad={setSkeletonLoad}
            skeletonLoad={skeletonLoad}
            renamingId={(id) => renamingId(id)}
            isChanged={isRename}
            activeRename={() => setIsRename(true)}
            updateTemplate={updateTemplate}
            CategoryList={CategoryList}
            AllCategoryList={AllCategoryList}
            setAllCategoryList={setAllCategoryList}
            LoadList={(value) => {
              setLoad(load + 1);
              LoadList(value);
            }}
            TriggerClick={() => setOutsideClick(true)}
            outsideClick={outsideClick}
            templateId={location?.state?.id}
            setShowLoader={setShowLoader}
          />
        </div>
        <div className="layout2">
          <CategoryDetails
            summaryEdit={summaryEdit}
            summary={summary}
            setSummary={setSummary}
            LoadList={() => setLoad(load + 1)}
            DiscardTemplateReq={DiscardTemplateReq}
            version={version}
            templateRatingDetails={templateRatingDetails}
            AllCategoryList={AllCategoryList}
            setAllCategoryList={setAllCategoryList}
            templateId={templateId}
            templateName={templateName}
            TogglerSwitch={TogglerSwitch}
            templateDetails={templateDetails}
            PublishTemplate={PublishTemplate}
            updateTemplate={updateTemplate}
            autoSave={autoSave}
            isAutoSaving={isAutoSaving}
            requestUpdate={requestUpdate}
            reRender={reRender}
            setReRender={setReRender}
            action={action}
            setAction={setAction}
            successfulRatingChange={successfulRatingChange}
            defaultTemplate={defaultTemplate}
          />
        </div>
      </div>
      <EditTemplateList
        open={addNew}
        isEdit={isEdit}
        setTemplateDetails={setTemplateDetails}
        typeDetails={typeDetails}
        setTypeDetails={setTypeDetails}
        setBasedDetails={setBasedDetails}
        basedDetails={basedDetails}
        templateId={location.state?.id}
        refresh={loadDetails}
        load={() => setLoad(load + 1)}
        onClose={handleCloseNew}
      />
    </>
  );
};

export default EditTemplate;
