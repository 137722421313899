import React from "react";
import Box from "@mui/material/Box";

// common components imports
import AgreementWebViewNSignature from "../../../components/AgreementsDetails/AgreementWebViewNSignature";

const AgreementWebView = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <AgreementWebViewNSignature />
      </Box>
    </>
  );
};

export default AgreementWebView;
