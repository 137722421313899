import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./FroalaEditorStyles.scss";
import "../../../assets/styles/containers/_commentLibraryModal.scss";

function EditOptionsDialog(props) {
  const { state, setState, handleSave } = props;
  const { open, newOption, dialogOptionList } = state;
  const [isEdit, setIsEdit] = useState(-1);

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], textfield: false, editedOption: null }));
  };

  const handleOptionBlur = () => {
    if (isEdit !== -1) {
      let updatedOptions = [...dialogOptionList];
      if (newOption.trim() === "") {
        updatedOptions = dialogOptionList.filter((_, i) => i !== isEdit);
      } else {
        updatedOptions[isEdit] = newOption;
      }
      setState((prevState) => ({ ...prevState, dialogOptionList: updatedOptions, newOption: "" }));
      setIsEdit(-1);
    }
  };

  const handleOptionClick = (option, index) => {
    if (isEdit !== index) setIsEdit(index);
    setState((prevState) => ({ ...prevState, newOption: option }));
  };

  const handleOptionChange = (e, index) => {
    if (isEdit === index) {
      setState((prevState) => ({ ...prevState, newOption: e.target.value }));
    }
  };

  const handleDelete = (index) => {
    let newDropdownOptions = dialogOptionList.filter((_, i) => i !== index);
    setState((prevState) => ({ ...prevState, dialogOptionList: newDropdownOptions }));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedList = Array.from(dialogOptionList);
    const [movedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, movedItem);
    setState((prevState) => ({ ...prevState, dialogOptionList: updatedList }));
    if (isEdit !== -1) {
      setIsEdit(-1);
      setState((prevState) => ({ ...prevState, newOption: "" }));
    }
  };

  const onDragStart = () => {
    if (isEdit !== -1) handleOptionBlur();
  };

  return (
    <Dialog open={open} classes={{ paper: "edit-dialog-paper" }} onClose={() => handleCloseDialog()} maxWidth="md">
      <DialogTitle className="edit-dialog-title">{`Edit dropdown options`}</DialogTitle>
      <Divider className="edit-dialog-divider" />
      <DialogContent>
        <Grid container>
          <Grid container item xs={12} display={"flex"}>
            <Grid item xs={11} className="option-element">
              <DragIndicatorIcon className="option-drag-icon" sx={{ marginTop: "-1px" }} />
              <TextField value={"-------------"} size="small" InputProps={{ readOnly: true }} className="option-textfield" />
            </Grid>
            <Grid item xs={1} mt={-0.3} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
              <IconButton disabled={true}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div style={{ width: "100%" }} ref={provided.innerRef} {...provided.droppableProps}>
                  {dialogOptionList.map((option, index) => (
                    <Draggable key={`option_${index}`} draggableId={String(`option_${index}`)} index={index}>
                      {(provided) => (
                        <Grid container item xs={12} display={"flex"} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={option}>
                          <Grid item xs={11} className="option-element">
                            <div {...provided.dragHandleProps}>
                              <DragIndicatorIcon className="option-drag-icon" />
                            </div>
                            <TextField
                              value={isEdit === index ? newOption : option}
                              size="small"
                              onClick={() => handleOptionClick(option, index)}
                              onChange={(e) => handleOptionChange(e, index)}
                              onBlur={() => handleOptionBlur(index)}
                              className="option-textfield"
                              inputProps={{ maxLength: 250 }}
                            />
                          </Grid>
                          <Grid item xs={1} mt={-0.3} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                            <IconButton onClick={() => handleDelete(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </DialogContent>
      <Divider className="edit-dialog-divider" />
      <DialogActions>
        <Grid container my={0.6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item display={"flex"} justifyContent={"flex-start"}>
            <Button
              startIcon={<AddCircleOutlineOutlinedIcon sx={{ fontSize: "18px" }} />}
              className="add-option-button"
              onClick={() => setState((prevState) => ({ ...prevState, dialogOptionList: [...dialogOptionList, ""] }))}
            >
              Add option
            </Button>
          </Grid>
          <Grid item display={"flex"} justifyContent={"flex-end"} gap={1}>
            <Button className="edit-dialog-cancel-btn" onClick={() => handleCloseDialog()}>
              Cancel
            </Button>
            <Button className="edit-dialog-save-btn" variant="contained" onClick={() => handleSave()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default EditOptionsDialog;
