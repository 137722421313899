import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import UploadIcon from "@mui/icons-material/Upload";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { toast } from "react-hot-toast";

function FileUpload(props) {
  const {
    progressPercent,
    file,
    state,
    handleUploadFile,
    sendEmailToDevTeam,
    handleDownloadErrorFile,
    handleCloseDialog,
    cancelDisabled,
  } = props;

  const onDrop = async (acceptedFiles) => {
    if (
      acceptedFiles.length > 0 &&
      (acceptedFiles[0]?.name.endsWith(".xls") ||
        acceptedFiles[0]?.name.endsWith(".xlsx"))
    ) {
      handleUploadFile(acceptedFiles[0]);
    } else {
      toast.error("File format is not correct.");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.ms-excel",
    onDrop,
    maxFiles: 1,
  });

  return (
    <>
      <div {...getRootProps()}>
        <Grid container display={"flex"} className="uploadBox">
          <input {...getInputProps()} />
          <Grid item xs={12} display={"flex"} justifyContent={"center"} my={2}>
            <UploadIcon />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            my={1}
            gap={1}
          >
            <Typography className="uploadTitle">{`Click to upload`}</Typography>
            <Typography className="ptUploadDesc">{`or drag and drop`}</Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"} my={1}>
            <Typography sx={{ color: "#cbcdd1" }}>
              {"Maximum file size 500 MB."}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {file && (
        <Box className="fileBox">
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2}>
                  <Card className="ptFileCard">
                    <DescriptionOutlinedIcon className="ptFileIcon" />
                  </Card>
                </Grid>
                <Grid item xs={9}>
                  <Typography className="ptFileName">{file?.name}</Typography>
                  <Typography className="ptFileSize">{`${Math.round(
                    (file?.size || 0) / 1024
                  )} KB`}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => handleCloseDialog(true, "file")}
                    disabled={cancelDisabled()}
                    className="closeButton"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {progressPercent !== 100 ? (
              <Grid container mt={2} gap={2}>
                <Grid item xs={10.5}>
                  <LinearProgress
                    variant="determinate"
                    value={progressPercent}
                  />
                </Grid>
                <Grid item xs={1} mt={-1}>
                  <Typography>{`${progressPercent}%`}</Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      )}
      {state?.fileImportError && (
        <Grid container mb={2}>
          <Grid item xs={12}>
            <Typography
              fontWeight={400}
              fontSize={12}
            >{`There was a problem with the file.`}</Typography>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={6} display={"flex"} gap={1}>
              <DownloadIcon className="templateContactUs" />
              <Typography
                fontWeight={400}
                fontSize={12}
                value="download"
                onClick={() => handleDownloadErrorFile()}
                className="ptDownloadBtn"
              >{`Download error report`}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} gap={1}>
              <ShortcutIcon className="templateContactUs" />
              <Typography
                onClick={() => sendEmailToDevTeam()}
                fontWeight={400}
                fontSize={12}
                className="ptDownloadBtn"
              >{`Send to dev team`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default FileUpload;
