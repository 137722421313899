import React from "react";
import { Button } from "@mui/material";

const TemplateEditorButton = ({btnType ,text, action, id, disabled = false}) => {
  return (
    <>
      <Button id={id} className={`temp_editor_cta ${btnType}`} disabled={disabled} onClick={action} >
        {text}
      </Button>
    </>
  );
};

export default TemplateEditorButton;
