import { Box } from '@mui/system';
import Header from '../../common/header/Header';
import Breadcrumb from '../../common/header/Breadcrumb';
import EditAgent from '../../components/agentListView/EditAgent';

function EditAgents() {
    return (
        <>
            {window.scrollTo(0, 0)}
            <Box sx={{ flexGrow: 1 }}>
                <EditAgent />
            </Box>
        </>
    );
}

export default EditAgents;
