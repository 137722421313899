//imports****************************************************************
import { Box } from "@mui/system";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import AgentListView from "../../components/agentListView/AgentListView";

// render function*******************************************************
function Agents() {
  //return function******************************************************
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <AgentListView />
      </Box>
    </>
  );
}

export default Agents;
