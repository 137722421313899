import React from 'react'
import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const PaymentMadeTemplate = () => {
  return (
    <>
    <EmailTemplate title='Email Payment Confirmation'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='email_payment_confirmation'
    />
    </>
  )
}

export default PaymentMadeTemplate