import React from 'react'
import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const InspectionPublishedTemplate = () => {
  return (
    <>
    <EmailTemplate title='Email Inspection Published Confirmation'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='email_inspection_published_confirmation'
    />
    </>
  )
}

export default InspectionPublishedTemplate