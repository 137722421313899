import React from 'react'
import { Box } from "@mui/system";
import Breadcrumb from '../../../common/header/Breadcrumb'
import Header from '../../../common/header/Header'
import PTCompanyListView from '../../../components/PTadmin/Company/PTCompanyListView';

const PTCompanyList = () => {
  return (
    <>
     <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header/>
        <Breadcrumb/>
        <PTCompanyListView/>
    </Box>
    </>
  )
}

export default PTCompanyList